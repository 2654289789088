













































































































































































































































































import {
  createSalesperson,
  deleteUser,
  getSalespeople,
  getSalespersonTypes,
  setSalespersonType,
  setUserActivationStatus
} from "@/api";
import {
  ActionError,
  SnackbarColor,
  Salesperson,
  ValidationErrors,
  SalespersonType,
  RoleName,
  SalespeopleQuery
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import UserForm from "@/views/users/UserForm.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";
import EmptyCard from "@/components/EmptyCard.vue";

const defaultSalesperson: Salesperson = {
  id: 0,
  name: "",
  lastName: "",
  userName: "",
  email: "",
  profileImage: "",
  isActive: false,
  salespersonType: {
    id: 0,
    name: "",
    description: "",
    isActive: false,
    dailyContactsTarget: 0,
    availableContactTypes: []
  },
  portfolios: [],
  roles: []
};

interface SalespersonsData {
  salespersons: Salesperson[];
  error: string;
  headers: DataTableHeader[];
  deletedSalesperson: Salesperson;
  deleting: boolean;
  showDeleteModal: boolean;
  deletionErrors: ActionError[];
  createdSalesperson: Salesperson;
  creating: boolean;
  showCreationModal: boolean;
  errors: ValidationErrors;
  showDeletionErrors: boolean;
  salespersonTypes: SalespersonType[];

  // Set salesperson type.
  showSalespersonTypeModal: boolean;
  editedSalesperson: Salesperson;
  salespersonTypeId: number;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle,
    ButtonToqui,
    Modal,
    UserForm,
    EmptyCard
  },
  data(): SalespersonsData {
    return {
      salespersons: [],
      error: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          width: "20%"
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "type"
        },
        {
          text: "Carteras",
          align: "start",
          sortable: true,
          value: "portfolios",
          width: "40%"
        },
        {
          text: "¿Activo?",
          align: "start",
          sortable: false,
          value: "active"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      deletedSalesperson: { ...defaultSalesperson },
      deleting: false,
      showDeleteModal: false,
      deletionErrors: [],
      creating: false,
      createdSalesperson: { ...defaultSalesperson },
      showCreationModal: false,
      errors: {},
      showDeletionErrors: false,
      salespersonTypes: [],
      showSalespersonTypeModal: false,
      editedSalesperson: { ...defaultSalesperson },
      salespersonTypeId: 0,
      editing: false
    };
  },
  mounted() {
    this.getSalespeople();
    this.getSalespersonTypes();
  },
  methods: {
    getSalespeople() {
      const salespeopleQuery: SalespeopleQuery = {
        includeInactive: true,
      };
      getSalespeople(salespeopleQuery)
        .then((response: AxiosResponse<Salesperson[]>) => {
          this.salespersons = response.data;
        })
        .catch(() => {
          this.error = "Ha habido un problema cargando la información";
        });
    },
    getSalespersonTypes() {
      getSalespersonTypes().then(
        (response: AxiosResponse<SalespersonType[]>) => {
          this.salespersonTypes = response.data;
        }
      );
    },
    deleteSalesperson() {
      this.deleting = true;
      deleteUser(this.deletedSalesperson)
        .then(() => {
          this.getSalespeople();
        })
        .finally(() => {
          this.cancelDeletion();
          this.deleting = false;
        });
    },
    startDeletion(salesperson: Salesperson) {
      this.deletedSalesperson = { ...salesperson };
      this.showDeleteModal = true;
    },
    cancelDeletion() {
      this.deletedSalesperson = { ...defaultSalesperson };
      this.showDeleteModal = false;
    },
    createSalesperson() {
      this.creating = true;
      createSalesperson(this.createdSalesperson)
        .then(() => {
          this.getSalespeople();
          this.createdSalesperson = { ...defaultSalesperson };
          this.showCreationModal = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    startCreation() {
      this.createdSalesperson = { ...defaultSalesperson };
      this.showCreationModal = true;
    },
    cancelCreation() {
      this.createdSalesperson = { ...defaultSalesperson };
      this.showCreationModal = false;
    },
    closeDeletionErrors() {
      this.deletionErrors = [];
      this.showDeletionErrors = false;
    },
    setActivationStatus(salesperson: Salesperson) {
      setUserActivationStatus(salesperson, salesperson.isActive)
        .then(() => {
          let text = salesperson.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `El usuario ${salesperson.email} ha sido ${text} exitosamente.`
          });
        })
        .catch(() => {
          let text = salesperson.isActive ? "activando" : "desactivando";
          salesperson.isActive = !salesperson.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este usuario.`
          });
        });
    },
    startSwitchingSalespersonType(salesperson: Salesperson) {
      this.showSalespersonTypeModal = true;
      this.editedSalesperson = { ...salesperson };
      this.salespersonTypeId = salesperson.salespersonType.id;
    },
    setSalespersonType() {
      this.editing = true;
      setSalespersonType(this.editedSalesperson, this.salespersonTypeId)
        .then(() => {
          this.getSalespeople();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `El tipo del vendedor ${this.editedSalesperson.name} ${this.editedSalesperson.lastName} ha sido modificado exitosamente.`
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `El tipo del vendedor ${this.editedSalesperson.name} ${this.editedSalesperson.lastName} no ha sido podido ser modificado.`
          });
        })
        .finally(() => {
          this.editedSalesperson = { ...defaultSalesperson };
          this.editing = false;
          this.showSalespersonTypeModal = false;
        });
    }
  },
  computed: {
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName
  }
});
