












































































































































































































































































import { getDailyReportPayload } from "@/api";
import { DailyReportPayload } from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";

interface DailyReportDetailsData {
  preview: DailyReportPayload | null;
}

export default Vue.extend({
  data(): DailyReportDetailsData {
    return {
      preview: null
    };
  },
  mounted() {
    const id: number = +this.$route.params.id;
    getDailyReportPayload(id).then(
      (response: AxiosResponse<DailyReportPayload>) => {
        this.preview = response.data;
      }
    );
  },
  methods: {
    contactTypeColor(contactTypeName: string) {
      if (contactTypeName == "En frío") {
        return "primary";
      }
      if (contactTypeName == "Agendado") {
        return "secondary";
      }
      if (contactTypeName == "Inercia") {
        return "accent";
      }
    }
  }
});
