










































import {
  getCustomSelectableFields,
  getCustomTextFields,
  getSectors
} from "@/api";
import {
  Client,
  CustomSelectableField,
  CustomTextField,
  Sector,
  ValidationErrors
} from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";

interface SectorsData {
  sectors: Sector[];
  selectableFields: CustomSelectableField[];
  textFields: CustomTextField[];
}

export default Vue.extend({
  props: {
    client: Object as () => Client,
    errors: Object as () => ValidationErrors
  },
  data(): SectorsData {
    return {
      sectors: [],
      selectableFields: [],
      textFields: [],
    };
  },
  mounted() {
    this.getSectors();
    getCustomSelectableFields("Clients").then(
      (response: AxiosResponse<CustomSelectableField[]>) => {
        this.selectableFields = response.data;
        if (
          !Object.prototype.hasOwnProperty.call(this.client, "selectableFields")
        ) {
          this.client.selectableFields = {};
        }
        // Inicializando campos seleccionables personalizados.
        for (let i = 0; i < this.selectableFields.length; i++) {
          const selectableField = this.selectableFields[i];
          if (
            !Object.prototype.hasOwnProperty.call(
              this.client.selectableFields,
              selectableField.name
            )
          ) {
            this.client.selectableFields[selectableField.name] = {
              id: 0,
              value: ""
            };
          }
        }
      }
    );

    getCustomTextFields("Clients").then(
      (response: AxiosResponse<CustomTextField[]>) => {
        this.textFields = response.data;
        if (!Object.prototype.hasOwnProperty.call(this.client, "textFields")) {
          this.client.textFields = {};
        }
        // Inicializando campos de texto personalizados.
        for (let i = 0; i < this.textFields.length; i++) {
          const textField = this.textFields[i];
          if (
            !Object.prototype.hasOwnProperty.call(
              this.client.textFields,
              textField.name
            )
          ) {
            this.client.textFields[textField.name] = "";
          }
        }
      }
    );
  },
  methods: {
    getSectors() {
      getSectors().then((response: AxiosResponse<Sector[]>) => {
        this.sectors = response.data;
      });
    }
  }
});
