












































































import Vue from "vue";
import { BusinessUnit, ValidationErrors } from "@/models";
import {
  createBusinessUnit,
  deleteBusinessUnit,
  editBusinessUnit,
  getBusinessUnits
} from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";

interface EditableBusinessUnit extends BusinessUnit {
  editing: boolean;
}

interface BusinessUnitsState {
  businessUnits: EditableBusinessUnit[];
  newBusinessUnit: BusinessUnit;
  creationErrors: ValidationErrors;
  editionErrors: ValidationErrors;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle
  },
  data(): BusinessUnitsState {
    return {
      businessUnits: [],
      newBusinessUnit: {
        id: 0,
        name: "",
        deletable: false
      },
      creationErrors: {},
      editionErrors: {},
      editing: false
    };
  },
  computed: {
    Kind: () => Kind
  },
  mounted() {
    this.getBusinessUnits();
  },
  methods: {
    getBusinessUnits() {
      getBusinessUnits().then((response: AxiosResponse<BusinessUnit[]>) => {
        this.businessUnits = response.data.map(x => {
          return {
            ...x,
            editing: false
          };
        });
      });
    },
    createBusinessUnit() {
      createBusinessUnit(this.newBusinessUnit)
        .then(() => {
          this.newBusinessUnit = {
            id: 0,
            name: "",
            deletable: false
          };
          this.getBusinessUnits();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.creationErrors = { ...error.response.data.errors };
          }
        });
    },
    deleteBusinessUnit(businessUnit: BusinessUnit) {
      deleteBusinessUnit(businessUnit).then(() => {
        this.getBusinessUnits();
      });
    },
    startEdition(businessUnit: EditableBusinessUnit) {
      businessUnit.editing = true;
      this.editing = true;
    },
    editBusinessUnit(businessUnit: EditableBusinessUnit) {
      editBusinessUnit(businessUnit)
        .then(() => {
          this.getBusinessUnits();
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.editionErrors = { ...error.response.data.errors };
          }
        });
    }
  }
});
