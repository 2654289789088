var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{attrs:{"width":"100vw","transition":"dialog-bottom-transition","title":"Agenda"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-row',[_c('v-col',[_c('v-sheet',[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"ma-4",attrs:{"icon":""},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"d-flex justify-center fill-width py-3 primary--text"},[_c('span',{staticClass:"text-h4 font-weight-medium"},[_vm._v(_vm._s(_vm.monthNames[_vm.date.month()]))]),_c('span',{staticClass:"text-h4 px-5"},[_vm._v("-")]),_c('span',{staticClass:"text-h4 font-weight-medium"},[_vm._v(_vm._s(_vm.date.format("YYYY")))])]),_c('v-btn',{staticClass:"ma-4",attrs:{"icon":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('v-sheet',{staticClass:"d-flex",attrs:{"height":"54"}},[_c('v-select',{staticClass:"ma-2",attrs:{"return-object":"","items":_vm.types,"item-text":"name","dense":"","outlined":"","hide-details":"","label":"Tipo"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-sheet',{attrs:{"height":"70vh"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-calendar',{ref:"calendar",attrs:{"events":_vm.events,"event-overlap-mode":"stack","event-overlap-threshold":30,"event-over":"","color":"primary","type":_vm.type.value,"weekdays":[1, 2, 3, 4, 5, 6, 0],"start":_vm.start,"event-more":""},on:{"click:event":_vm.showContact,"click:more":_vm.goToDay,"click:date":_vm.goToDay,"change":_vm.getEvents},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"p-1"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center mx-1"},[(!event.contact.hasBeenInformed && !event.contact.aborted)?_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-alert-circle")]):_vm._e(),_c('div',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(event.contact.client.name)+" ")])],1),_c('div',[_c('div',{staticClass:"ml-8"},[_vm._v(" ("+_vm._s(event.start.substring(11, 16))+"- "+_vm._s(event.end.substring(11, 16))+") ")])])])]}}],null,true)},[_c('span',[_vm._v("Contacto "+_vm._s(_vm.contactKindName(event.contact.contactType.kind))+" ")]),_vm._v(" "),(event.contact.aborted)?_c('span',[_vm._v("(Abortado)")]):_vm._e(),(!event.contact.hasBeenInformed)?_c('span',[_vm._v("(No Informado)")]):_vm._e()])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1),_c('modal',{attrs:{"title":"Detalles del contacto","width":"900"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.displayContact = false}}},[_vm._v(" Cerrar ")])]},proxy:true}]),model:{value:(_vm.displayContact),callback:function ($$v) {_vm.displayContact=$$v},expression:"displayContact"}},[(_vm.selectedContact)?_c('div',[(_vm.selectedContact)?_c('div',{staticClass:"d-flex px-5 text-body-2"},[_c('contact-details',{attrs:{"selectedContact":_vm.selectedContact},on:{"deleted":function($event){return _vm.reloadEvents()},"updated":function($event){return _vm.reloadEvents()}}})],1):_vm._e()]):_vm._e()])],1)],1),_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"z-index":"20001"},attrs:{"color":"primary","dark":"","fixed":"","bottom":"","left":"","fab":"","large":_vm.largeViewport,"small":!_vm.largeViewport},on:{"click":_vm.showCalendar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-account-outline")])],1)]}}])},[_c('span',[_vm._v("Agenda")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }