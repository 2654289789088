





























































































































































































































































































































import {
  confirmNexus,
  createNexus,
  deleteNexus,
  editNexus,
  moveNexus,
  rejectNexus,
  setMainNexus,
  setNexusActivationStatus
} from "@/api";
import {
  Client,
  Facility,
  Nexus,
  RoleName,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import ContextMenuOption from "@/components/ContextMenuOption.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import Modal from "@/components/Modal.vue";
import { AxiosError } from "axios";
import NexusForm from "./NexusForm.vue";
import { MutationTypes } from "@/store";
import { authorizeRoles } from "@/helpers";

interface NexusesTableData {
  // Table data.
  headers: DataTableHeader[];

  // Creation, edition and deletion.
  editedNexus: ExtendedNexus;
  deletedNexus: Nexus;
  errors: ValidationErrors;
  editing: boolean;
  showEditForm: boolean;
  deleting: boolean;
  showDeleteModal: boolean;
  showCreateForm: boolean;
  creating: boolean;
  createdNexus: Nexus;
  selectedFacilityId: number;

  // Client confirmation.
  confirmedNexusId: number;
  showConfirmationModal: boolean;

  // Client rejection.
  rejectedNexusId: number;
  showRejectionModal: boolean;

  // Confirmation or rejection.
  processing: boolean;
  clientName: string;
}

interface ExtendedNexus extends Nexus {
  facilityName: string;
  facilityIdentifier: string;
  facilityId: number;
}

const defaultNexus: Nexus = {
  id: 0,
  name: "",
  lastName: "",
  email: "",
  phone: "",
  mobilePhone: "",
  position: "",
  officeNumber: "",
  isMain: false,
  isConfirmed: false,
  isActive: false
};

const defaultExtendedNexus: ExtendedNexus = {
  ...defaultNexus,
  facilityName: "",
  facilityIdentifier: "",
  facilityId: 0
};

export default Vue.extend({
  components: {
    ButtonCircle,
    Modal,
    NexusForm,
    ButtonToqui,
    RequireConfirmation,
    ContextMenu,
    ContextMenuOption
  },
  props: {
    client: Object as () => Client
  },
  data(): NexusesTableData {
    return {
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Instalación",
          align: "start",
          sortable: false,
          value: "facility"
        },
        {
          text: "Formas de contacto",
          align: "start",
          sortable: false,
          value: "contactForms"
        },
        {
          text: "¿Activo?",
          align: "start",
          sortable: false,
          value: "active"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      editedNexus: { ...defaultExtendedNexus },
      deletedNexus: { ...defaultNexus },
      errors: {},
      editing: false,
      showEditForm: false,
      deleting: false,
      showDeleteModal: false,
      showCreateForm: false,
      creating: false,
      createdNexus: { ...defaultNexus },
      selectedFacilityId: 0,
      confirmedNexusId: 0,
      showConfirmationModal: false,
      rejectedNexusId: 0,
      showRejectionModal: false,
      processing: false,
      clientName: ""
    };
  },
  computed: {
    nexuses(): ExtendedNexus[] {
      const nexuses: ExtendedNexus[] = [];
      this.client.facilities.forEach((x: Facility) => {
        x.nexuses.forEach(element => {
          nexuses.push({
            facilityName: x.name,
            facilityIdentifier: x.identifier,
            facilityId: x.id,
            ...element
          });
        });
      });
      return nexuses;
    },
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    startCreation() {
      this.showCreateForm = true;
      this.selectedFacilityId = this.client.facilities[0].id;
    },
    createNexus() {
      this.creating = true;
      createNexus(this.selectedFacilityId, this.createdNexus)
        .then(() => {
          this.$emit("update");
          this.cancelCreation();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
          this.creating = false;
        });
    },
    cancelCreation() {
      this.createdNexus = { ...defaultNexus };
      this.creating = false;
      this.showCreateForm = false;
      this.errors = {};
    },
    startEdition(nexus: ExtendedNexus) {
      this.showEditForm = true;
      this.editedNexus = { ...nexus };
      this.selectedFacilityId = nexus.facilityId;
    },
    editNexus() {
      this.editing = true;
      editNexus(this.editedNexus)
        .then(() => {
          if (this.selectedFacilityId !== this.editedNexus.facilityId) {
            moveNexus(this.selectedFacilityId, this.editedNexus)
              .then(() => {
                this.cancelEdition();
              })
              .catch(() => {
                this.$store.commit(MutationTypes.SHOW_SNACK, {
                  text: "No se pudo cambiar de instalación a este nexo.",
                  color: SnackbarColor.error
                });
              })
              .finally(() => {
                this.$emit("update");
              });
          } else {
            this.cancelEdition();
            this.$emit("update");
          }
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
          this.editing = false;
        })
        .finally(() => {
          this.editing = false;
        });
    },
    cancelEdition() {
      this.editedNexus = { ...defaultExtendedNexus };
      this.editing = false;
      this.showEditForm = false;
      this.selectedFacilityId = 0;
      this.errors = {};
    },
    startDeletion(nexus: Nexus) {
      this.showDeleteModal = true;
      this.deletedNexus = { ...nexus };
    },
    deleteNexus() {
      deleteNexus(this.deletedNexus)
        .then(() => {
          this.$emit("update");
        })
        .finally(() => {
          this.cancelDeletion();
        });
    },
    cancelDeletion() {
      this.showDeleteModal = false;
      this.deletedNexus = { ...defaultNexus };
    },
    scheduleContact(nexus: Nexus) {
      this.$store.commit(MutationTypes.SHOW_SCHEDULE_CONTACT_FORM, {
        nexusId: nexus.id
      });
    },
    informUnscheduledContact(nexus: Nexus) {
      this.$store.commit(MutationTypes.SHOW_INFORM_UNSCHEDULED_CONTACT_FORM, {
        nexusId: nexus.id
      });
    },
    setMain(nexus: Nexus) {
      setMainNexus(nexus.id)
        .then(() => {
          // Making all nexuses but this 'not main'.
          const unMainedNexuses: Nexus[] = this.nexuses.filter(
            x => x.id != nexus.id
          );
          for (let i = 0; i < unMainedNexuses.length; i++) {
            unMainedNexuses[i].isMain = false;
          }
          nexus.isMain = true;
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text:
              "Ha habido un problema cambiando el nexo principal de este cliente.",
            color: SnackbarColor.error
          });
        });
    },
    startConfirmation(client: Client) {
      this.showConfirmationModal = true;
      this.confirmedNexusId = client.id;
      this.clientName = client.name;
    },
    confirm() {
      this.processing = true;
      confirmNexus(this.confirmedNexusId)
        .then(() => {
          this.$emit("update");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente confirmado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema confirmando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelConfirmation();
        });
    },
    cancelConfirmation() {
      this.showConfirmationModal = false;
      this.confirmedNexusId = 0;
    },
    startRejection(client: Client) {
      this.showRejectionModal = true;
      this.rejectedNexusId = client.id;
      this.clientName = client.name;
    },
    reject() {
      this.processing = true;
      rejectNexus(this.rejectedNexusId)
        .then(() => {
          this.$emit("update");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente rechazado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema rechazando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelRejection();
        });
    },
    cancelRejection() {
      this.showRejectionModal = false;
      this.rejectedNexusId = 0;
    },
    setActivationStatus(nexus: Nexus) {
      setNexusActivationStatus(nexus, nexus.isActive)
        .then(() => {
          let text = nexus.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `El nexo ${nexus.email} ha sido ${text} exitosamente.`
          });
        })
        .catch(() => {
          let text = nexus.isActive ? "activando" : "desactivando";
          nexus.isActive = !nexus.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este usuario.`
          });
        });
    }
  }
});
