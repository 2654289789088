















import Vue from "vue";

interface TimeInputData {
  formattedTime: string;
  firstFocus: boolean;
}

export default Vue.extend({
  props: {
    value: String,
    label: String,
    required: Boolean,
    error: Boolean,
    errorCount: {
      type: [Number, String],
      default: 2
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    messages: {
      type: [String, Array],
      default: () => []
    },
    readonly: Boolean,
    success: Boolean,
    successMessages: {
      type: [String, Array],
      default: () => []
    },
    prependIcon: String
  },
  data(): TimeInputData {
    return {
      formattedTime: this.value.substring(0, 5),
      firstFocus: false
    };
  },
  methods: {
    handleInput(value: string) {
      this.formattedTime = value;
      if (this.formattedTime.length == 2) {
        this.formattedTime = `${this.formattedTime}:`;
      }
      if (this.formattedTime.length == 5) this.$emit("input", this.formattedTime);
    },
    filterInput(value: KeyboardEvent) {
      // If first input, replace current content.
      if (this.formattedTime.length == 5 && this.firstFocus) 
      {
        this.handleInput(value.key);
        this.firstFocus = false;
        value.preventDefault();
        return;
      }
      if (this.formattedTime.length == 5)
      {
        value.preventDefault();
        return;
      }
      // First time digit
      if (this.formattedTime.length == 0 && value.key.match(/[^0-2]/g))
      {
        value.preventDefault();
      }
      // Second time digit
      else if (this.formattedTime.length == 1 && this.formattedTime == '2' && value.key.match(/[^0-3]/g))
      {
        value.preventDefault();
      }
      else if (this.formattedTime.length == 1 && value.key.match(/[^0-9]/g))
      {
        value.preventDefault();
      }
      // Third time digit
      else if (this.formattedTime.length == 3 && value.key.match(/[^0-5]/g))
      {
        value.preventDefault();
      }
      // Fourth time digit
      else if (this.formattedTime.length == 0 && value.key.match(/[^0-9]/g))
      {
        value.preventDefault();
      }
    },
    handleBackspace()
    {
      if (this.formattedTime.length == 5 && this.firstFocus) 
      {
        this.handleInput('');
        this.firstFocus = false;
        return;
      }
    }
  },
  watch: {
    value: function(): void {
      if (this.formattedTime != this.value) this.formattedTime = this.value.substring(0, 5);
    }
  }
});
