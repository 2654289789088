

























import { InformScheduledContactData, ValidationErrors } from '@/models'
import Vue from 'vue'
export default Vue.extend({
    props: {
        contact: Object as () => InformScheduledContactData,
        errors: Object as () => ValidationErrors
    }
})
