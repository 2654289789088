




























































































































































































import {
  createUser,
  deleteUser,
  getUsers,
  setUserActivationStatus,
  setUserRoles
} from "@/api";
import {
  ActionError,
  SnackbarColor,
  User,
  ValidationErrors,
  roleNameString,
  RoleName
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import UserForm from "./UserForm.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";
import { authorizeRoles } from "@/helpers";

const defaultUser: User = {
  id: 0,
  name: "",
  lastName: "",
  userName: "",
  email: "",
  profileImage: "",
  isActive: false,
  roles: []
};

interface UsersData {
  users: User[];
  error: string;
  headers: DataTableHeader[];
  deletedUser: User;
  deleting: boolean;
  showDeleteModal: boolean;
  deletionErrors: ActionError[];
  createdUser: User;
  creating: boolean;
  showCreationModal: boolean;
  errors: ValidationErrors;
  showDeletionErrors: boolean;

  // Assign roles.
  showRolesModal: boolean;
  switchingRoles: boolean;
  switchingRolesUser: User;
  roles: { value: RoleName; text: string }[];
}

export default Vue.extend({
  components: {
    ButtonCircle,
    ButtonToqui,
    Modal,
    UserForm
  },
  data(): UsersData {
    return {
      users: [],
      error: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "roles"
        },
        {
          text: "¿Activo?",
          align: "start",
          sortable: false,
          value: "active"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      deletedUser: { ...defaultUser },
      deleting: false,
      showDeleteModal: false,
      deletionErrors: [],
      creating: false,
      createdUser: { ...defaultUser },
      showCreationModal: false,
      errors: {},
      showDeletionErrors: false,

      // Assign roles.
      switchingRoles: false,
      showRolesModal: false,
      switchingRolesUser: { ...defaultUser },
      roles: [
        {
          value: RoleName.Administrator,
          text: roleNameString(RoleName.Administrator)
        },
        { value: RoleName.Director, text: roleNameString(RoleName.Director) }
        // { value: RoleName.Supervisor, text: roleNameString(RoleName.Supervisor) },
        // { value: RoleName.Salesperson, text: roleNameString(RoleName.Salesperson) },
      ]
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    rolesToString(roles: RoleName[]) {
      return roles.map((x: RoleName) => roleNameString(x)).join(", ");
    },
    getUsers() {
      getUsers()
        .then((response: AxiosResponse<User[]>) => {
          this.users = response.data;
        })
        .catch(() => {
          this.error = "Ha habido un problema cargando la información";
        });
    },
    deleteUser() {
      this.deleting = true;
      deleteUser(this.deletedUser)
        .then(() => {
          this.getUsers();
        })
        .finally(() => {
          this.cancelDeletion();
          this.deleting = false;
        });
    },
    startDeletion(user: User) {
      this.deletedUser = { ...user };
      this.showDeleteModal = true;
    },
    cancelDeletion() {
      this.deletedUser = { ...defaultUser };
      this.showDeleteModal = false;
    },
    createUser() {
      this.creating = true;
      createUser(this.createdUser)
        .then(() => {
          this.getUsers();
          this.createdUser = { ...defaultUser };
          this.showCreationModal = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    startCreation() {
      this.createdUser = { ...defaultUser };
      this.showCreationModal = true;
    },
    cancelCreation() {
      this.createdUser = { ...defaultUser };
      this.showCreationModal = false;
    },
    closeDeletionErrors() {
      this.deletionErrors = [];
      this.showDeletionErrors = false;
    },
    setActivationStatus(user: User) {
      setUserActivationStatus(user, user.isActive)
        .then(() => {
          let text = user.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `El usuario ${user.email} ha sido ${text} exitosamente.`
          });
        })
        .catch(() => {
          let text = user.isActive ? "activando" : "desactivando";
          user.isActive = !user.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este usuario.`
          });
        });
    },
    startSwitchingRoles(user: User) {
      this.switchingRolesUser = { ...user };
      this.showRolesModal = true;
    },
    switchRoles() {
      this.switchingRoles = true;
      setUserRoles(this.switchingRolesUser.id, this.switchingRolesUser.roles)
        .then(() => {
          this.getUsers();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `Roles modificados satisfactoriamente.`
          });
        })
        .catch(() => {
          // TODO: handle failures here.
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error modificando los roles.`
          });
        })
        .finally(() => {
          this.showRolesModal = false;
          this.switchingRoles = false;
        });
    }
  },
  computed: {
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName
  }
});
