






















































































import { getNonWorkingDays, setNonWorkingDays } from "@/api";
import { SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";
import Vue from "vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";

interface ComponentState {
  dates: string[];
  menu: boolean;
  loading: boolean;
  year: number;
}

export default Vue.extend({
  components: {
    ButtonToqui
  },
  data(): ComponentState {
    return {
      dates: [],
      menu: false,
      loading: false,
      year: dayjs().year()
    };
  },
  mounted() {
    this.getNonWorkingDays();
  },
  methods: {
    getNonWorkingDays() {
      this.loading = true;
      getNonWorkingDays(this.year).then((response: AxiosResponse<Dayjs[]>) => {
        console.log(response.data);
        this.dates = response.data.map((x: Dayjs) => {
          return x.format("YYYY-MM-DD");
        });
        this.loading = false;
      });
    },
    setNonWorkingDays() {
      this.loading = true;
      const selectedDates: Dayjs[] = this.dates.map((x: string) => dayjs(x));
      setNonWorkingDays(selectedDates)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Feriados actualizados exitosamente.",
            color: SnackbarColor.success
          });
          this.loading = false;
        })
        .catch(() => {
          this.getNonWorkingDays();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Ha habido un problema actualizando los feriados.",
            color: SnackbarColor.error
          });
          this.loading = false;
        });
    },
    selectYear(year: number) {
      this.year = year;
      this.getNonWorkingDays();
    }
  },
  computed: {
    ToquiKind: () => ToquiKind
  }
});
