


































































































































































import { createPortfolio, deletePortfolio, editPortfolio, getPortfolios } from "@/api";
import { BusinessSegment, Portfolio, SnackbarColor, ValidationErrors } from "@/models";
import Vue from "vue";
import EmptyCard from "@/components/EmptyCard.vue";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import PortfolioForm from "./PortfolioForm.vue";
import PortfolioCard from "@/components/PortfolioCard.vue";
import Modal from "@/components/Modal.vue";
import { MutationTypes } from "@/store";

interface SegmentPortfolioData {
  portfolios: Portfolio[];
  loading: boolean;
  validationErrors: ValidationErrors;
  formPortfolio: Portfolio;
  deleting: boolean;
  showDeleteModal: boolean;
  deletingPortfolio: Portfolio;
  creating: boolean;
  editing: boolean;
  showEditModal: boolean;
}

const defaultPortfolio: Portfolio = {
  id: 0,
  name: "",
  clientsCount: 0,
};

export default Vue.extend({
  props: {
    segment: {
      type: Object as () => BusinessSegment
    },
    showCreateModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EmptyCard,
    ButtonCircle,
    PortfolioForm,
    PortfolioCard,
    Modal,
    ButtonToqui
  },
  data(): SegmentPortfolioData {
    return {
      portfolios: this.segment.portfolios,
      loading: true,
      validationErrors: {},
      formPortfolio: { ...defaultPortfolio },
      deleting: false,
      showDeleteModal: false,
      deletingPortfolio: { ...defaultPortfolio },
      creating: false,
      editing: false,
      showEditModal: false
    };
  },
  computed: {
    Kind: () => Kind,
    ToquiKind: () => ToquiKind
  },
  methods: {
    getPortfolios() {
      getPortfolios(null, this.segment.id).then(
        (response: AxiosResponse<Portfolio[]>) => {
          this.portfolios = response.data;
          this.loading = false;
        }
      );
    },
    createPortfolio() {
      this.creating = true;
      createPortfolio(this.segment, this.formPortfolio)
        .then(() => {
          this.getPortfolios();
          this.$emit("close-creation");
          this.formPortfolio = { ...defaultPortfolio };
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    editPortfolio() {
      this.editing = true;
      this.validationErrors = {};
      editPortfolio(this.formPortfolio)
        .then(() => {
          this.getPortfolios();
          this.formPortfolio = { ...defaultPortfolio };
          this.showEditModal = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.editing = false;
        });
    },
    deletePortfolio() {
      this.deleting = true;
      deletePortfolio(this.deletingPortfolio)
        .then(() => {
          this.getPortfolios();
        })
        .catch(() => {
          // TODO: esto no necesariamente es así, y hay que manejarlo de una manera mejor.
          this.$store.commit(MutationTypes.SHOW_SNACK,  {
            text: "Esta cartera no puede ser eliminada, ya que tiene información asociada.",
            color: SnackbarColor.error
          })
        })
        .finally(() => {
          this.deleting = false;
          this.showDeleteModal = false;
        });
    },
    cancelCreation() {
      this.$emit("close-creation");
      this.validationErrors = {};
      this.formPortfolio = { ...defaultPortfolio };
    },
    cancelEdition() {
      this.showEditModal = false;
      this.validationErrors = {};
      this.formPortfolio = { ...defaultPortfolio };
    },
    startDeletion(portfolio: Portfolio) {
      this.deletingPortfolio = { ...portfolio };
      this.showDeleteModal = true;
    },
    startEdition(portfolio: Portfolio) {
      this.formPortfolio = { ...portfolio };
      this.showEditModal = true;
    }
  }
});
