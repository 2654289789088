






import QuotesTable from "@/views/quotes/QuotesTable.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    QuotesTable,
  }
});
