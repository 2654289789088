





















import { backendUrl, getPendingConfirmationsCount } from "@/api";
import { AxiosResponse } from "axios";
import * as signalR from "@microsoft/signalr";
import Vue from "vue";

interface PendingConfirmationsData {
  pendingConfirmationsCount: number;
}

export default Vue.extend({
  data(): PendingConfirmationsData {
    return {
      pendingConfirmationsCount: 0
    };
  },
  mounted() {
    getPendingConfirmationsCount().then((response: AxiosResponse<number>) => {
      this.pendingConfirmationsCount = response.data;
    });
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${backendUrl}/confirmationsHub`)
      .build();
    connection.on("updatePendingConfirmationsCount", (count: number) => {
      this.pendingConfirmationsCount = count;
      console.log(`pending confirmation count updated ${this.pendingConfirmationsCount}`)
    });
    connection.start().catch(error => console.error(error));
  },
  computed: {
    confirmationsLinkName(): string {
      if (this.$router.options.routes) {
        const bc = this.$router.options.routes.filter(
          x => x.path === "/confirmations"
        )[0].meta.breadcrumb;
        return bc[bc.length - 1].text;
      }
      return "";
    }
  }
});
