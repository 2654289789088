











































































































































import { getClients, getCoverageExcel, getDailyCoverages, getPortfolios } from "@/api";
import {
  PaginatedResult,
  ClientsQuery,
  DailyCoverages,
  Portfolio,
  Client
} from "@/models";
import { AxiosResponse } from "axios";
import { Dayjs } from "dayjs";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import dayjs from "dayjs";
import { es } from "@/config";

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

interface CoverageData {
  loading: boolean;
  // Table data, filters, pagination and errors.
  totalContactTypes: number;
  options: DataOptions;
  headers: DataTableHeader[];
  coverages: DailyCoverages[];
  month: number;
  year: number;
  portfolioId: number;
  clientId: number;
  daysInMonth: number[];
  totals: number[];
  globalTotal: number;

  portfolios: Portfolio[];
  clients: { id: number; name: string }[];

  years: number[];
  months: { id: number; name: string }[];

  exporting: boolean;
}

const headers: DataTableHeader[] = [];

const currentDay: Dayjs = dayjs();

export default Vue.extend({
  data(): CoverageData {
    return {
      loading: false,
      totalContactTypes: 0,
      headers,
      options: { ...initialOptions },
      coverages: [],
      year: currentDay.year(),
      month: currentDay.month() + 1,
      portfolioId: 0,
      clientId: 0,
      daysInMonth: [],
      portfolios: [],
      clients: [],
      years: [],
      months: [],
      totals: [],
      globalTotal: 0,
      exporting: false
    };
  },
  mounted() {
    this.getDataFromApi();
    this.getPortfolios();
    this.getClients();
    const currentYear = currentDay.year();
    for (let year = currentYear - 5; year <= currentYear; year++) {
      this.years.push(year);
    }
    for (let month = 1; month <= es.options.months.length; month++) {
      this.months.push({
        id: month,
        name: es.options.months[month - 1]
      });
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.updateHeaders();
      getDailyCoverages(
        this.year,
        this.month,
        this.portfolioId == 0 ? undefined : this.portfolioId,
        this.clientId == 0 ? undefined : this.clientId
      )
        .then((response: AxiosResponse<DailyCoverages[]>) => {
          this.coverages = response.data;
          this.totals = [];
          if (this.coverages.length > 0) {
            for (let i = 0; i < this.coverages.length; i++) {
              const portfolioCoverages = this.coverages[i];
              for (let j = 0; j < portfolioCoverages.values.length; j++) {
                const coverage = portfolioCoverages.values[j];
                if (!this.totals[j]) {
                  this.totals.push(0);
                }
                this.totals[j] += coverage;
                this.globalTotal += coverage;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadExcel()
    {
      this.exporting = true;
      getCoverageExcel(
        this.year,
        this.month,
        this.portfolioId == 0 ? undefined : this.portfolioId,
        this.clientId == 0 ? undefined : this.clientId
      ).finally(() => { this.exporting = false; })
    },
    getPortfolios() {
      getPortfolios().then((response: AxiosResponse<Portfolio[]>) => {
        this.portfolios = response.data;
        this.portfolios.unshift({
          id: 0,
          name: "Todas las carteras",
          clientsCount: 0
        });
      });
      // TODO: handle failures
    },
    getClients() {
      const query: ClientsQuery = {
        page: 1,
        resultsPerPage: 100000
      };
      getClients(query).then(
        (response: AxiosResponse<PaginatedResult<Client>>) => {
          this.clients = response.data.data;
          this.clients.unshift({
            id: 0,
            name: "Todas los clientes"
          });
        }
      );
      // TODO: handle failures
    },
    updateHeaders() {
      const date = dayjs(new Date(this.year, this.month - 1, 1));
      this.daysInMonth = [];
      this.headers = [];
      this.headers.push({
        text: "Tipo de contacto",
        align: "start",
        sortable: false,
        value: "contactType"
      });
      for (let i = 1; i <= date.daysInMonth(); i++) {
        this.daysInMonth.push(i);
        this.headers.push({
          text: i.toString(),
          align: "start",
          sortable: false,
          value: i.toString()
        });
      }
      this.headers.push({
        text: "Total",
        align: "start",
        sortable: false,
        value: "total"
      });
    }
  },
  watch: {
    portfolioId() {
      this.clientId = 0;
      this.getDataFromApi();
    },
    clientId() {
      this.portfolioId = 0;
      this.getDataFromApi();
    }
  }
});
