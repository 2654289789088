












































































import Vue from "vue";
import { Brand, ValidationErrors } from "@/models";
import { createBrand, deleteBrand, editBrand, getBrands } from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";

interface EditableBrand extends Brand {
  editing: boolean;
}

interface BrandsState {
  brands: EditableBrand[];
  newBrand: Brand;
  creationErrors: ValidationErrors;
  editionErrors: ValidationErrors;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle
  },
  data(): BrandsState {
    return {
      brands: [],
      newBrand: {
        id: 0,
        name: "",
        deletable: false
      },
      creationErrors: {},
      editionErrors: {},
      editing: false
    };
  },
  computed: {
    Kind: () => Kind
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    getBrands() {
      getBrands().then((response: AxiosResponse<Brand[]>) => {
        this.brands = response.data.map(x => {
          return {
            ...x,
            editing: false
          };
        });
      });
    },
    createBrand() {
      createBrand(this.newBrand)
        .then(() => {
          this.newBrand = {
            id: 0,
            name: "",
            deletable: false
          };
          this.getBrands();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.creationErrors = { ...error.response.data.errors };
          }
        });
    },
    deleteBrand(brand: Brand) {
      deleteBrand(brand).then(() => {
        this.getBrands();
      });
    },
    startEdition(brand: EditableBrand) {
      brand.editing = true;
      this.editing = true;
    },
    editBrand(brand: EditableBrand) {
      editBrand(brand)
        .then(() => {
          this.getBrands();
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.editionErrors = { ...error.response.data.errors };
          }
        });
    }
  }
});
