













































import Vue from "vue";
import AppBar from "@/components/AppBar.vue";
import ScheduleContactForm from "@/components/contacts/ScheduleContactForm.vue";
import InformUnscheduledContactForm from "@/components/contacts/InformUnscheduledContactForm.vue";
import ContactsCalendar from "@/components/contacts/ContactsCalendar.vue";
import ControlPanel from "@/components/ControlPanel.vue";
import { mapState } from "vuex";
import { ActionTypes, MutationTypes } from "./store";
import numeral from "numeral";

numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€"
  }
});

numeral.locale("es");
numeral.defaultFormat("0,0.[00]");

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    ScheduleContactForm,
    InformUnscheduledContactForm,
    ContactsCalendar,
    ControlPanel
  },
  data: () => ({
    breadcrumbList: [],
    displayElements: true,
    fab: false
  }),
  mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
    this.displayElements = !this.$route.meta.noLayout ?? false;
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      this.displayElements = !this.$route.meta.noLayout ?? false;
    }
  },
  methods: {
    refreshApp() {
      this.$store.dispatch(ActionTypes.REFRESH_APP);
    },
    closeSnackbar() {
      this.$store.commit(MutationTypes.SET_SHOW_SNACK, false);
    }
  },
  computed: {
    // Mapping state getter and mutation reference to a local computed property.
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(v) {
        return this.$store.commit(MutationTypes.SET_SHOW_SNACK, v);
      }
    },
    // Mapping the state of the other snacbkar properties.
    ...mapState(["snackbar.color", "snackbar.text", "authenticated", "update"])
  }
});
