






import Vue from "vue";
import GapDocumentsTable from "./GapDocumentsTable.vue";
export default Vue.extend({
  components: {
    GapDocumentsTable
  }
});
