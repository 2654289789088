






















































import { Nexus, ValidationErrors } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    nexus: Object as () => Nexus,
    errors: Object as () => ValidationErrors
  }
});
