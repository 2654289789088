









import { ConfirmationType } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    kind: {
      type: String as () => ConfirmationType
    }
  },
  computed: {
    ConfirmationType: () => ConfirmationType,
    color() {
      switch (this.kind) {
        case ConfirmationType.Client:
          return "teal";
        case ConfirmationType.Facility:
          return "green";
        case ConfirmationType.Nexus:
          return "indigo";
        case ConfirmationType.GapDocument:
          return "blue-grey";
        case ConfirmationType.Sale:
          return "red";
        default:
          return "primary";
      }
    },
    text() {
      switch (this.kind) {
        case ConfirmationType.Client:
          return "Cliente";
        case ConfirmationType.Facility:
          return "Instalación";
        case ConfirmationType.Nexus:
          return "Nexo";
        case ConfirmationType.GapDocument:
          return "Documento de Brecha";
        case ConfirmationType.Sale:
          return "Venta";
        default:
          return "No definido";
      }
    },
    icon() {
      switch (this.kind) {
        case ConfirmationType.Client:
          return "mdi-account-circle-outline";
        case ConfirmationType.Facility:
          return "mdi-office-building-outline";
        case ConfirmationType.Nexus:
          return "mdi-account-tie-outline";
        case ConfirmationType.GapDocument:
          return "mdi-text-box-multiple-outline";
        case ConfirmationType.Sale:
          return "mdi-cash-multiple";
        default:
          return "No definido";
      }
    }
  }
});
