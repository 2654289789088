
















































































import {
  getOperativeBalance,
  getOperativeBalanceExcel,
  getSalespeople
} from "@/api";
import { OperativeBalance, Salesperson } from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";

interface OperativeBalanceData {
  data: OperativeBalance[];
  totalData: number;
  salespeople: Salesperson[];
  selectedSalespersonId: number;

  isSalesperson: boolean;

  // Table
  loading: boolean;
  options: DataOptions;
  headers: DataTableHeader[];

  // Excel
  exporting: boolean;
}

const initialOptions: DataOptions = {
  sortBy: ["bdn"],
  sortDesc: [true],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

export default Vue.extend({
  data(): OperativeBalanceData {
    return {
      data: [],
      totalData: 0,
      salespeople: [],
      selectedSalespersonId: 0,
      loading: false,
      options: { ...initialOptions },
      isSalesperson: this.$store.state.user.isSalesperson,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "clientId"
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "clientName"
        },
        {
          text: "BDN",
          align: "start",
          sortable: true,
          value: "bdn"
        },
        {
          text: "Brecha",
          align: "start",
          sortable: true,
          value: "gap"
        },
        {
          text: "BDP",
          align: "start",
          sortable: true,
          value: "bdp"
        },
        {
          text: "C. agendados",
          align: "start",
          sortable: true,
          value: "scheduledContacts"
        },
        {
          text: "C. en frío",
          align: "start",
          sortable: true,
          value: "coldContacts"
        },
        {
          text: "C. inercia",
          align: "start",
          sortable: true,
          value: "inertiaContacts"
        },
        {
          text: "Facturación",
          align: "start",
          sortable: true,
          value: "billing"
        }
      ],
      exporting: false
    };
  },
  mounted() {
    if (!this.isSalesperson) {
      getSalespeople().then((response: AxiosResponse<Salesperson[]>) => {
        this.salespeople = response.data;
        this.totalData = this.salespeople.length;
        if (this.salespeople.length > 0)
          this.selectedSalespersonId = this.salespeople[0].id;
        this.getOperativeBalance();
      });
      // TODO: handle failures here.
    } else {
      this.selectedSalespersonId = this.$store.state.user.id;
      this.getOperativeBalance();
    }
  },
  methods: {
    getOperativeBalance() {
      this.loading = true;
      getOperativeBalance(this.selectedSalespersonId)
        .then((response: AxiosResponse<OperativeBalance[]>) => {
          this.data = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
      // TODO: handle failures
    },
    downloadExcel() {
      this.exporting = true;
      getOperativeBalanceExcel().finally(() => {
        this.exporting = false;
      });
    }
  }
});
