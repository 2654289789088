
























































import { resetPassword } from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import { ResetPasswordData } from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {
    ButtonToqui
  },
  data() {
    return {
      password: "",
      showPassword: false,
      passwordConfirmation: "",
      showPasswordConfirmation: false,
      errors: {},
      passwordResetToken: '',
      userId: '',
      message: '',
      success: false,
      failed: false
    };
  },
  mounted() {
    this.passwordResetToken = this.$route.query.token?.toString() ?? '';
    this.userId = this.$route.query.user?.toString() ?? '';
  },
  methods: {
    resetPassword()
    {
      this.errors = {};
      this.failed = false;
      this.success = false;
      this.message = '';
      const passwordResetData: ResetPasswordData = {
        passwordResetToken: this.passwordResetToken,
        userId: parseInt(this.userId),
        password: this.password,
        passwordConfirmation: this.passwordConfirmation
      };
      resetPassword(passwordResetData)
      .then((response: AxiosResponse<string>) => {
        this.message = response.data;
        this.success = true;
        this.password = '';
        this.passwordConfirmation = '';
        passwordResetData.password = '';
        passwordResetData.passwordConfirmation = '';
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status == 400)
        {
          this.errors = error.response.data.errors;
          this.failed = true;
        }
        else if (error.response &&  (error.response.status == 409 || error.response.status == 404))
        {
          this.message = error.response.data;
          this.failed = true;
        }
      });
    }
  },
  computed: {
    ToquiKind: () => ToquiKind,
    messageColor() {
      if(this.success)
      {
        return 'secondary';
      }
      if (this.failed)
      {
        return 'error';
      }
      return 'primary';
    }
  }
});
