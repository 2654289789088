






import Vue from "vue";
import SalesTable from "./SalesTable.vue";
export default Vue.extend({
  components: {
    SalesTable
  }
});
