







































































































































































































































import { editClient, getClient, getLastContact } from "@/api";
import { Client, Contact, RoleName, ValidationErrors } from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
import NexusesTable from "./NexusesTable.vue";
import FacilitiesTable from "./FacilitiesTable.vue";
import ContactsTable from "./ContactsTable.vue";
import PortfolioTypeChip from "@/components/PortfolioTypeChip.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import { authorizeRoles } from "@/helpers";
import ClientForm from "./ClientForm.vue";
import Modal from "@/components/Modal.vue";
import ClientSector from "@/components/ClientSector.vue";
import ClientCustomFields from "@/components/ClientCustomFields.vue";

interface Data {
  client: Client;
  clientEdition: Client;
  loading: boolean;
  error: string;
  tab: string;
  showEditModal: boolean;
  editing: boolean;
  clientErrors: ValidationErrors;
  lastContact: Contact | null;
}

const defaultClient: Client = {
        id: 0,
        name: "",
        portfolios: [],
        facilities: [],
        isConfirmed: false,
        sector: {
          id: 0,
          name: '',
          deletable: false
        },
        textFields: {},
        selectableFields: {},
        isDeletable: false
      };

export default Vue.extend({
  components: {
    NexusesTable,
    PortfolioTypeChip,
    ButtonCircle,
    FacilitiesTable,
    ContactsTable,
    ClientForm,
    Modal,
    ButtonToqui,
    ClientSector,
    ClientCustomFields
  },
  data(): Data {
    return {
      client: { ...defaultClient },
      loading: false,
      error: "",
      tab: "",
      editing: false,
      showEditModal: false,
      clientErrors: {},
      clientEdition: { ...defaultClient },
      lastContact: null
    };
  },
  mounted() {
    this.getClient();
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    getClient() {
      const id: number = +this.$route.params.id;
      getClient(id)
        .then((response: AxiosResponse<Client>) => {
          this.client = response.data;
          this.clientEdition = { ...this.client }
        })
        .catch(() => {
          this.error = "Ha habido un error consultando los datos";
        });
      getLastContact(id).then((response: AxiosResponse<Contact>) => {
        this.lastContact = response.data;
      });
    },
    editClient() {
      this.editing = true;
      editClient(this.clientEdition)
        .then(() => {
          this.getClient();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.clientErrors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.editing = false;
          this.showEditModal = false;
        });
    },
    cancelClientEdition() {
      this.showEditModal = false;
      this.clientErrors = {};
    }
  },
  computed: {
    nexusesCount() {
      let sum = 0;
      this.client.facilities.forEach(x => {
        sum += x.nexuses.length;
      });
      return sum;
    },
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName
  }
});
