var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contacts},scopedSlots:_vm._u([{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateTime).toLocaleString("es-CL"))+" ")]}},{key:"item.nexus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nexus.name)+" "+_vm._s(item.nexus.lastName)+" "),_c('div',{staticClass:"text-caption font-italic"},[_vm._v(" "+_vm._s(item.nexus.email)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(item.contactType.name)+" ")]),_c('div',{staticClass:"d-flex justify-start"},[_c('contact-kind-chip',{attrs:{"kind":item.contactType.kind}})],1)]}},{key:"item.objective",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.objective.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-circle',{staticClass:"mr-2",attrs:{"kind":_vm.Kind.view,"text":"Gestionar"},on:{"click":function($event){return _vm.view(item)}}})]}}],null,true)}),_c('modal',{attrs:{"title":"Detalles del contacto","width":"900"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.displayContact = false}}},[_vm._v(" Cerrar ")])]},proxy:true}]),model:{value:(_vm.displayContact),callback:function ($$v) {_vm.displayContact=$$v},expression:"displayContact"}},[(_vm.selectedContact)?_c('div',[(_vm.selectedContact)?_c('div',{staticClass:"d-flex px-5 text-body-2"},[_c('contact-details',{attrs:{"selectedContact":_vm.selectedContact},on:{"deleted":function($event){return _vm.getContacts()},"updated":function($event){return _vm.getContacts()}}})],1):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }