var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dailyReports,"options":_vm.options,"server-items-length":_vm.totalDailyReports,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.salesperson",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"40"}},[(!item.salesperson.profileImage)?_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(("" + (item.salesperson.name[0]) + (item.salesperson.lastName[0]))))]):_vm._e(),(item.salesperson.profileImage)?_c('img',{attrs:{"src":item.salesperson.profileImage}}):_vm._e()]),_c('div',[_c('div',{staticClass:"font-weight-bold text-subtitle-2"},[_vm._v(" "+_vm._s(item.salesperson.name)+" "+_vm._s(item.salesperson.lastName)+" ")]),_c('div',{staticClass:"text-caption font-italic"},[_vm._v(" "+_vm._s(item.salesperson.email)+" ")])])],1)]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.sent)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_vm._e(),(!item.sent)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"accent"}},[_vm._v("mdi-close")]):_vm._e()],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.format("DD-MM-YYYY"))+" ")]}},{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [_c('button-circle',{attrs:{"kind":_vm.Kind.view,"to":("/daily_reports/" + (item.id)),"text":'Revisar vista previa',"disabled":!item.pdfGenerated}}),_c('button-circle',{attrs:{"kind":_vm.Kind.pdf,"disabled":!item.pdfGenerated},on:{"click":function($event){return _vm.downloadPdf(item.id)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }