
























































import { getContacts } from "@/api";
import ContactKindChip from "@/components/ContactKindChip.vue";
import { Contact, ContactsQuery } from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import { DataTableHeader } from "vuetify";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ContactDetails from "@/components/contacts/ContactDetails.vue";

interface ContactsTableData {
  headers: DataTableHeader[];
  contacts: Contact[];
  displayContact: boolean;
  selectedContact?: Contact;
}

export default Vue.extend({
  components: {
    ContactKindChip,
    Modal,
    ButtonCircle,
    ContactDetails
  },
  props: {
    clientId: Number
  },
  data(): ContactsTableData {
    return {
      headers: [
        {
          text: "Fecha y hora",
          align: "start",
          sortable: true,
          value: "dateTime"
        },
        {
          text: "Nexo",
          align: "start",
          sortable: true,
          value: "nexus"
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: "Objetivo",
          align: "start",
          sortable: false,
          value: "objective"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      contacts: [],
      displayContact: false,
      selectedContact: undefined
    };
  },
  mounted() {
    this.getContacts();
  },
  methods: {
    view(contact: Contact) {
      this.displayContact = true;
      this.selectedContact = { ...contact };
    },
    getContacts() {
      this.displayContact = false;
      const contactsQuery: ContactsQuery = {
        clientId: this.clientId
      };
      getContacts(contactsQuery).then((response: AxiosResponse<Contact[]>) => {
        this.contacts = response.data;
        this.contacts.sort((a, b) => (a.dateTime > b.dateTime ? -1 : 1));
      });
    }
  },
  computed: {
    Kind: () => Kind
  }
});
