












































































import Vue from "vue";
import { Sector, ValidationErrors } from "@/models";
import { createSector, deleteSector, editSector, getSectors } from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";

interface EditableSector extends Sector {
  editing: boolean;
}

interface SectorsState {
  sectors: EditableSector[];
  newSector: Sector;
  creationErrors: ValidationErrors;
  editionErrors: ValidationErrors;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle
  },
  data(): SectorsState {
    return {
      sectors: [],
      newSector: {
        id: 0,
        name: "",
        deletable: false
      },
      creationErrors: {},
      editionErrors: {},
      editing: false
    };
  },
  computed: {
    Kind: () => Kind
  },
  mounted() {
    this.getSectors();
  },
  methods: {
    getSectors() {
      getSectors().then((response: AxiosResponse<Sector[]>) => {
        this.sectors = response.data.map(x => {
          return {
            ...x,
            editing: false
          };
        });
      });
    },
    createSector() {
      createSector(this.newSector)
        .then(() => {
          this.newSector = {
            id: 0,
            name: "",
            deletable: false
          };
          this.getSectors();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.creationErrors = { ...error.response.data.errors };
          }
        });
    },
    deleteSector(sector: Sector) {
      deleteSector(sector).then(() => {
        this.getSectors();
      });
    },
    startEdition(sector: EditableSector) {
      sector.editing = true;
      this.editing = true;
    },
    editSector(sector: EditableSector) {
      editSector(sector)
        .then(() => {
          this.getSectors();
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.editionErrors = { ...error.response.data.errors };
          }
        });
    }
  }
});
