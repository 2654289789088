






















































































































































































































































































import {
  confirmFacility,
  createFacility,
  deleteFacility,
  editFacility,
  rejectFacility,
  setMainFacility
} from "@/api";
import {
  Client,
  Facility,
  SnackbarColor,
  ActionError,
  ValidationErrors,
  RoleName
} from "@/models";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import Modal from "@/components/Modal.vue";
import { AxiosError } from "axios";
import FacilityForm from "./FacilityForm.vue";
import { MutationTypes } from "@/store";
import { authorizeRoles } from "@/helpers";

interface FacilitiesTableData {
  // Table data.
  headers: DataTableHeader[];

  // Creation, edition and deletion.
  editedFacility: Facility;
  deletedFacility: Facility;
  errors: ValidationErrors;
  editing: boolean;
  showEditForm: boolean;
  deleting: boolean;
  showDeleteModal: boolean;
  showCreateForm: boolean;
  creating: boolean;
  createdFacility: Facility;
  deletionErrors: ActionError[];
  showDeletionErrors: boolean;

  // Client confirmation.
  confirmedFacilityId: number;
  showConfirmationModal: boolean;

  // Client rejection.
  rejectedFacilityId: number;
  showRejectionModal: boolean;

  // Confirmation or rejection.
  processing: boolean;
  facilityName: string;
}

const defaultFacility: Facility = {
  id: 0,
  name: "",
  identifier: "",
  nexuses: [],
  isMain: false,
  isConfirmed: false,
  region: "",
  province: "",
  commune: "",
  communeId: 0,
  address: "",
  countryId: 0,
  country: ""
};

export default Vue.extend({
  components: {
    ButtonCircle,
    Modal,
    FacilityForm,
    ButtonToqui,
    RequireConfirmation
  },
  props: {
    client: Object as () => Client
  },
  data(): FacilitiesTableData {
    return {
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Dirección",
          align: "start",
          sortable: false,
          value: "address"
        },
        {
          text: "Información",
          align: "start",
          sortable: false,
          value: "info"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      editedFacility: { ...defaultFacility },
      deletedFacility: { ...defaultFacility },
      errors: {},
      editing: false,
      showEditForm: false,
      deleting: false,
      showDeleteModal: false,
      showCreateForm: false,
      creating: false,
      createdFacility: { ...defaultFacility },
      deletionErrors: [],
      showDeletionErrors: false,
      confirmedFacilityId: 0,
      showConfirmationModal: false,
      rejectedFacilityId: 0,
      showRejectionModal: false,
      processing: false,
      facilityName: ""
    };
  },
  computed: {
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName,
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    startCreation() {
      this.showCreateForm = true;
    },
    createFacility() {
      this.creating = true;
      createFacility(this.client.id, this.createdFacility)
        .then(() => {
          this.$emit("update");
          this.cancelCreation();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
          this.creating = false;
        });
    },
    cancelCreation() {
      this.createdFacility = { ...defaultFacility };
      this.creating = false;
      this.showCreateForm = false;
      this.errors = {};
    },
    startEdition(facility: Facility) {
      this.showEditForm = true;
      this.editedFacility = { ...facility };
    },
    editFacility() {
      this.editing = true;
      editFacility(this.editedFacility)
        .then(() => {
          this.$emit("update");
          this.cancelEdition();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
          this.editing = false;
        });
    },
    cancelEdition() {
      this.editedFacility = { ...defaultFacility };
      this.editing = false;
      this.showEditForm = false;
      this.errors = {};
    },
    startDeletion(facility: Facility) {
      this.showDeleteModal = true;
      this.deletedFacility = { ...facility };
    },
    deleteFacility() {
      deleteFacility(this.deletedFacility)
        .then(() => {
          this.$emit("update");
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.deletionErrors = error.response.data.errors;
            this.showDeletionErrors = true;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "La instalación no ha podido ser eliminada.",
              color: SnackbarColor.error
            });
          }
        })
        .finally(() => {
          this.cancelDeletion();
        });
    },
    cancelDeletion() {
      this.showDeleteModal = false;
      this.deletedFacility = { ...defaultFacility };
    },
    closeDeletionErrors() {
      this.deletionErrors = [];
      this.showDeletionErrors = false;
    },
    setMain(facility: Facility) {
      setMainFacility(facility.id)
        .then(() => {
          // Making all nexuses but this 'not main'.
          const unMainedFacilities: Facility[] = this.client.facilities.filter(
            x => x.id != facility.id
          );
          for (let i = 0; i < unMainedFacilities.length; i++) {
            unMainedFacilities[i].isMain = false;
          }
          facility.isMain = true;
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text:
              "Ha habido un problema cambiando la instalación principal de este cliente.",
            color: SnackbarColor.error
          });
        });
    },
    startConfirmation(client: Client) {
      this.showConfirmationModal = true;
      this.confirmedFacilityId = client.id;
      this.facilityName = client.name;
    },
    confirm() {
      this.processing = true;
      confirmFacility(this.confirmedFacilityId)
        .then(() => {
          this.$emit("update");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente confirmado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema confirmando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelConfirmation();
        });
    },
    cancelConfirmation() {
      this.showConfirmationModal = false;
      this.confirmedFacilityId = 0;
    },
    startRejection(client: Client) {
      this.showRejectionModal = true;
      this.rejectedFacilityId = client.id;
      this.facilityName = client.name;
    },
    reject() {
      this.processing = true;
      rejectFacility(this.rejectedFacilityId)
        .then(() => {
          this.$emit("update");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente rechazado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema rechazando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelRejection();
        });
    },
    cancelRejection() {
      this.showRejectionModal = false;
      this.rejectedFacilityId = 0;
    }
  }
});
