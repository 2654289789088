






























































































































































































































































































































import Modal from "@/components/Modal.vue";
import Vue from "vue";
import {
  PortfolioComposition,
  PortfolioIndicator,
  PortfolioType,
  portfolioTypeNameString,
  QuoteStatus
} from "@/models";
import Help from "@/components/Help.vue";
import { DataOptions, DataTableHeader } from "vuetify";
import {
  getBdn,
  getBdp,
  getGap,
  getIndicators,
  getIndicatorsExcel,
  getPortfolioComposition,
  getSalesIndicator
} from "@/api";
import { AxiosResponse } from "axios";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import numeral from "numeral";
import Coverage from "@/components/Coverage.vue";

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

interface ChartData {
  options: ApexOptions;
  series: number[];
  loading: boolean;
}

interface ControlPanelData {
  focus: string;
  loading: boolean;
  show: boolean;
  // Table data, filters, pagination and errors.
  totalPortfolios: number;
  options: DataOptions;
  headers: DataTableHeader[];
  search?: string;
  quoteStatus?: QuoteStatus;
  error: string | null;
  bdn: number;
  gap: number;
  bdp: number;
  sales: number;
  portfolioIndicators: PortfolioIndicator[];
  chartData: ChartData;
  tab: string;
  coverageKey: number;
  exportingIndicators: boolean;
}

export default Vue.extend({
  components: {
    Modal,
    Help,
    VueApexCharts,
    Coverage
  },
  computed: {
    largeViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return false;
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    mediumViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    }
  },
  data(): ControlPanelData {
    return {
      tab: "",
      bdn: 0,
      gap: 0,
      bdp: 0,
      sales: 0,
      portfolioIndicators: [],
      focus: "",
      show: false,
      totalPortfolios: 0,
      loading: false,
      options: initialOptions,
      error: null,
      coverageKey: 0,
      headers: [
        {
          text: "Cartera",
          align: "start",
          sortable: false,
          value: "portfolio"
        },
        {
          text: "Cobertura [%]",
          align: "start",
          sortable: false,
          value: "coverage"
        },
        {
          text: "Rendimiento [%]",
          align: "start",
          sortable: false,
          value: "rendimiento"
        },
        {
          text: "Desempeño [%]",
          align: "start",
          sortable: false,
          value: "desempeno"
        },
        {
          text: "Cotizaciones ganadas [%]",
          align: "start",
          sortable: false,
          value: "won"
        },
        {
          text: "Indicador BDN [%]",
          align: "start",
          sortable: false,
          value: "BDNIndicator"
        },
        {
          text: `BDP [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: false,
          value: "bdp"
        },
        {
          text: `BDN [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: false,
          value: "bdn"
        },
        {
          text: `Brecha [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: false,
          value: "gap"
        },
        {
          text: `Ventas [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: false,
          value: "sales"
        }
      ],
      chartData: {
        options: {
          labels: [],
          dataLabels: {
            formatter: function(value: number) {
              return `${numeral(value).format()}%`;
            }
          }
        },
        series: [],
        loading: true,
      },
      exportingIndicators: false
    };
  },
  methods: {
    numeral,
    showControlPanel() {
      this.show = !this.show;
      this.tab = "0";
      if (this.show) {
        this.getIndicators();
        this.getPortfolioComposition();
        this.coverageKey++;
      }
    },
    getIndicators() {
      getBdn().then(
        (response: AxiosResponse<number>) => (this.bdn = response.data)
      );
      getGap().then(
        (response: AxiosResponse<number>) => (this.gap = response.data)
      );
      getBdp().then(
        (response: AxiosResponse<number>) => (this.bdp = response.data)
      );
      getSalesIndicator().then(
        (response: AxiosResponse<number>) => (this.sales = response.data)
      );
      getIndicators().then((response: AxiosResponse<PortfolioIndicator[]>) => {
        this.portfolioIndicators = response.data;
      });
      // TODO: handle failures
    },
    getIndicatorsExcel() {
      this.exportingIndicators = true;
      getIndicatorsExcel().finally(() => {
        this.exportingIndicators = false;
      });
      // TODO: handle failures here
    },
    getPortfolioComposition() {
      this.chartData.loading = true;
      getPortfolioComposition().then(
        (response: AxiosResponse<PortfolioComposition>) => {
          this.chartData.options = {
            labels: response.data.labels.map((x: PortfolioType) =>
              portfolioTypeNameString(x)
            )
          };
          this.chartData.series = response.data.percentages;
          this.chartData.loading = false;
        }
      );
      // TODO: handle failures here.
    }
  }
});
