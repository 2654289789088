

































































































import Vue from "vue";
import { ProductsGroup, ValidationErrors } from "@/models";
import {
  createProductsGroup,
  deleteProductsGroup,
  editProductsGroup,
  getProductsGroups
} from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import NumberInput from "@/components/NumberInput.vue";

interface EditableProductsGroup extends ProductsGroup {
  editing: boolean;
}

interface ProductsGroupsState {
  productsGroups: EditableProductsGroup[];
  newProductsGroup: ProductsGroup;
  creationErrors: ValidationErrors;
  editionErrors: ValidationErrors;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle,
    NumberInput
  },
  data(): ProductsGroupsState {
    return {
      productsGroups: [],
      newProductsGroup: {
        id: 0,
        name: "",
        controlHorizonDays: 0,
        deletable: false
      },
      creationErrors: {},
      editionErrors: {},
      editing: false
    };
  },
  computed: {
    Kind: () => Kind
  },
  mounted() {
    this.getProductsGroups();
  },
  methods: {
    getProductsGroups() {
      getProductsGroups().then((response: AxiosResponse<ProductsGroup[]>) => {
        this.productsGroups = response.data.map(x => {
          return {
            ...x,
            editing: false
          };
        });
      });
    },
    createProductsGroup() {
      createProductsGroup(this.newProductsGroup)
        .then(() => {
          this.newProductsGroup = {
            id: 0,
            name: "",
            controlHorizonDays: 0,
            deletable: false
          };
          this.getProductsGroups();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.creationErrors = { ...error.response.data.errors };
          }
        });
    },
    deleteProductsGroup(productsGroup: ProductsGroup) {
      deleteProductsGroup(productsGroup).then(() => {
        this.getProductsGroups();
      });
    },
    startEdition(productsGroup: EditableProductsGroup) {
      productsGroup.editing = true;
      this.editing = true;
    },
    editProductsGroup(productsGroup: EditableProductsGroup) {
      editProductsGroup(productsGroup)
        .then(() => {
          this.getProductsGroups();
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.editionErrors = { ...error.response.data.errors };
          }
        });
    }
  }
});
