






























































import Parameters from "./Parameters.vue";
import NonWorkingDays from "./NonWorkingDays.vue";
import Vue from "vue";
import SalespersonTypes from "./SalespersonTypes.vue";
import ContactTypes from "./ContactTypes.vue";
import Objectives from "./Objectives.vue";
import ProductsGroups from "./ProductsGroups.vue";
import Sectors from "./Sectors.vue";
import Logo from "./Logo.vue";

export default Vue.extend({
  components: {
    Parameters,
    NonWorkingDays,
    SalespersonTypes,
    ContactTypes,
    Objectives,
    ProductsGroups,
    Sectors,
    Logo
  },
  data() {
    return {
      tab: null
    };
  }
});
