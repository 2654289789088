


















































import Vue from "vue";
import Brands from "./Brands.vue";
import BusinessUnits from "./BusinessUnits.vue";
import GeographicZones from "./GeographicZones.vue";
export default Vue.extend({
  components: {
    Brands,
    BusinessUnits,
    GeographicZones
  },
  data() {
    return {
      tab: null
    };
  }
});
