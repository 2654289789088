














import Vue from "vue";

export default Vue.extend({
  props: {
    disabled: Boolean,
    text: String,
    to: String,
    icon: String,
    color: {
      type: String,
      default: 'accent'
    }
  }
});
