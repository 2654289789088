









































































import {
  BaseScheduledContact,
  ContactKind,
  contactKindName,
  InformUnscheduledContactData,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import BaseScheduledContactForm from "@/components/contacts/BaseScheduledContactForm.vue";
import InformContactForm from "@/components/contacts/InformContactForm.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { informUnscheduledContact } from "@/api";
import { AxiosError } from "axios";
import { MutationTypes } from "@/store";
import dayjs from "dayjs";
import { mapState } from "vuex";

interface ContactFormData extends BaseScheduledContact {
  scheduleNextContact: boolean;
  agreements: string;
  nextActivity: string;
}

const defaultScheduleContact: ContactFormData = {
  nexusId: 0,
  dateTime: dayjs(),
  contactTypeId: 0,
  objectiveId: 0,
  portfolioId: 0,
  scheduleNextContact: false,
  agreements: "",
  nextActivity: "",
  observations: ""
};

interface ContactData {
  contact: ContactFormData;
  errors: ValidationErrors;
  selectedContactKind: ContactKind;
  saving: boolean;
  errorMessage: string;
  schedulingNextContact: boolean;
}

export default Vue.extend({
  // Mapping the state of the other snacbkar properties.
  components: {
    BaseScheduledContactForm,
    ButtonToqui,
    Modal,
    InformContactForm
  },
  computed: {
    ToquiKind: () => ToquiKind,
    ContactKind: () => ContactKind,
    ...mapState(["informUnscheduledContactForm"]),
  },
  data(): ContactData {
    return {
      contact: {
        ...defaultScheduleContact
      },
      errors: {},
      selectedContactKind: ContactKind.Cold,
      saving: false,
      errorMessage: "",
      schedulingNextContact: false,
    };
  },
  methods: {
    contactKindName,
    close() {
      this.saving = false;
      this.errors = {};
      this.contact.agreements = "";
      this.contact.nextActivity = "";
      this.schedulingNextContact = false;
      this.contact.scheduleNextContact = false;
      this.$store.commit(MutationTypes.HIDE_INFORM_UNSCHEDULED_CONTACT_FORM);
    },
    sleep(time: number) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    nextContact() {
      const nexusId = this.informUnscheduledContactForm.nexusId;
      this.close();
      this.sleep(500).then(() => {
        this.$store.commit(MutationTypes.SHOW_SCHEDULE_CONTACT_FORM, {
          nexusId: nexusId
        });
      });
    },
    saveContact() {
      this.saving = true;
      let informUnscheduledContactData: InformUnscheduledContactData = {
        nexusId: this.informUnscheduledContactForm.nexusId,
        dateTime: this.contact.dateTime,
        contactTypeId: this.contact.contactTypeId,
        objectiveId: this.contact.objectiveId,
        portfolioId: this.contact.portfolioId,
        agreements: this.contact.agreements,
        nextActivity: this.contact.nextActivity,
        observations: this.contact.observations,
        quoteId: this.informUnscheduledContactForm.quoteId
          ? this.informUnscheduledContactForm.quoteId
          : undefined
      };
      informUnscheduledContact(informUnscheduledContactData)
        .then(() => {
          if (!this.contact.scheduleNextContact) this.close();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contacto guardado exitosamente.",
            color: SnackbarColor.success
          });
          if (this.contact.scheduleNextContact)
          {
            this.nextContact();
          }
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "El contacto no ha podido ser informado. Revise la información ingresada en el formulario.",
            color: SnackbarColor.error
          });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
});
