

























































import { getCommunes, getCountries } from "@/api";
import { Commune, Country, Facility, ValidationErrors } from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";

interface FacilityFormData {
  communes: Commune[];
  countries: Country[];
  displayCommuneSelector: boolean;
}

export default Vue.extend({
  props: {
    facility: Object as () => Facility,
    errors: Object as () => ValidationErrors
  },
  data(): FacilityFormData {
    return {
      communes: [],
      countries: [],
      displayCommuneSelector: false
    };
  },
  mounted() {
    this.getCommunes();
    this.getCountries();
  },
  methods: {
    getCommunes() {
      getCommunes().then((response: AxiosResponse<Commune[]>) => {
        this.communes = response.data;
      });
      // TODO: handle failures here.
    },
    getCountries() {
      getCountries().then((response: AxiosResponse<Country[]>) => {
        if(this.facility.countryId == 0) this.facility.countryId = this.$store.state.parameters.countryId;
        this.countries = response.data;
      });
      // TODO: handle failures here.
    }
  },
  watch: {
    facility() {
      if(this.facility.countryId == 0) this.facility.countryId = this.$store.state.parameters.countryId;
    },
    'facility.countryId': function () {
      if (this.facility.countryId != this.$store.state.parameters.countryId)
      {
        this.facility.communeId = undefined;
        this.displayCommuneSelector = false;
      }
      else
      {
        this.displayCommuneSelector = true;
      }
    }
  }
});
