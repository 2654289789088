

















































































































































































import Vue from "vue";
import "animate.css";
import { mapState } from "vuex";
import { authorizeRoles } from "@/helpers";
import { ActionTypes } from "../store";
import MenuLink from "@/components/MenuLink.vue";
import PendingConfirmations from "@/components/PendingConfirmations.vue";
import { RoleName } from "@/models";

export default Vue.extend({
  name: "AppBar",
  components: {
    MenuLink,
    PendingConfirmations
  },
  computed: {
    largeViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return false;
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    accountLinkName(): string {
      if (this.$router.options.routes) {
        const bc = this.$router.options.routes.filter(
          x => x.path === "/account"
        )[0].meta.breadcrumb;
        return bc[bc.length - 1].text;
      }
      return "";
    },
    ...mapState(["authenticated", "user", "parameters"]),
    RoleName: () => RoleName
  },
  data: () => ({
    drawer: true,
    mini: false,
    environment: process.env.NODE_ENV
  }),
  methods: {
    logout() {
      this.$store.dispatch(ActionTypes.LOGOUT);
    },
    authorizeRoles
  }
});
