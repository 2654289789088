










































































































































































import { Vue } from "vue-property-decorator";
import {
  CreateClientData,
  defaultCreateClientData,
  Portfolio as PortfolioBase,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import {
  createClient,
  getPortfolios,
  validateFacility,
  validateNexus,
  validateClient
} from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import router from "@/router";
import Modal from "@/components/Modal.vue";
import ClientForm from "./ClientForm.vue";
import FacilityForm from "./FacilityForm.vue";
import NexusForm from "./NexusForm.vue";
import { NavigationGuardNext } from "vue-router";
import { MutationTypes } from "@/store";

interface CreateClientState {
  client: CreateClientData;
  stepNumber: number;
  errors: ValidationErrors;
  // TODO: esto no está en uso por ahora. Definir su uso o eliminar.
  generalError: string;
  portfolios: Portfolio[];

  saving: boolean;
  validating: boolean;

  // leaving guard
  next: NavigationGuardNext<Vue> | null;
  showLeavingModal: boolean;
  saved: boolean;
}

interface Portfolio extends PortfolioBase {
  selected: boolean;
}
export default Vue.extend({
  name: "CreateClient",
  components: {
    Modal,
    ClientForm,
    FacilityForm,
    NexusForm
  },
  beforeRouteLeave(to, from, route) {
    if (this.saved || to.name == "Login") {
      // If registration is completed, moving to next route.
      route();
    } else {
      this.showLeavingModal = true;
      this.next = route;
    }
  },
  // eslint-disable-next-line
  data(): CreateClientState {
    return {
      client: { ...defaultCreateClientData },
      stepNumber: 1,
      errors: {},
      generalError: "",
      portfolios: [],
      next: null,
      showLeavingModal: false,
      saving: true,
      validating: false,
      saved: false
    };
  },
  mounted() {
    this.getPortfolios();
  },
  methods: {
    createClient() {
      this.saving = true;
      this.errors = {};
      createClient(this.client)
        .then(response => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Nuevo cliente creado exitosamente.",
            color: SnackbarColor.success
          });
          this.saved = true;
          this.client = { ...defaultCreateClientData };
          router.push(`/clients/${response.data}`);
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
          this.saving = false;
        });
    },
    validateClient() {
      this.validating = true;
      this.errors = {};
      this.client.portfolioIds = this.portfolios
        .filter(x => x.selected)
        .map(x => x.id);
      validateClient(this.client)
        .then(() => {
          this.stepNumber = 2;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.validating = false;
        });
    },
    validateFacility() {
      this.validating = true;
      this.errors = {};
      this.client.facility.isMain = true;
      validateFacility(this.client.facility)
        .then(() => {
          this.stepNumber = 3;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.validating = false;
        });
    },
    validateNexusAndCreateClient() {
      this.validating = true;
      this.errors = {};
      this.client.nexus.isMain = true;
      validateNexus(this.client.nexus)
        .then(() => {
          // If valid at this point, creating client.
          this.createClient();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.validating = false;
        });
    },
    getPortfolios() {
      getPortfolios()
        .then((response: AxiosResponse<PortfolioBase[]>) => {
          this.portfolios = response.data.map((x: PortfolioBase) => {
            let portfolio: Portfolio = {
              ...x,
              selected: false
            };
            return portfolio;
          });
        })
        .catch(() => {
          this.generalError =
            "Hubo un problema cargando las carteras de clientes.";
        })
        .finally(() => {
          this.saving = false;
        });
    },
    updatePortfolioSelection(id: number) {
      for (let index = 0; index < this.portfolios.length; index++) {
        if (this.portfolios[index].id == id) {
          this.portfolios[index].selected = !this.portfolios[index].selected;
        }
      }
    },
    cancelClientRegistration() {
      router.push("/clients");
    },
    leave()
    {
      this.showLeavingModal = false;
      if (this.next) {
        this.next();
      }
    }
  }
});
