








































































































































































































































































































import { authorizeRoles } from "@/helpers";
import { getGoals, setPortfolioGoals } from "@/api";
import { PortfolioGoal, RoleName, SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import dayjs, { Dayjs } from "dayjs";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import numeral from "numeral";
import NumberInput from "@/components/NumberInput.vue";

interface ExtendedPortfolioGoal extends PortfolioGoal {
  editing: boolean;
  valuesBackup: number[];
  saving: boolean;
}

interface GoalsData {
  // Display data.
  date: Dayjs;
  goals: PortfolioGoal[];
  loading: boolean;

  // Table data.
  options: DataOptions;
  headers: DataTableHeader[];
}

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

export default Vue.extend({
  components: {
    ButtonCircle,
    NumberInput
  },
  data(): GoalsData {
    return {
      date: dayjs(),
      goals: [],
      loading: false,
      options: initialOptions,
      headers: [
        {
          text: "Cartera",
          align: "start",
          sortable: false,
          value: "portfolio",
          width: "15%"
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions"
        },
        {
          text: "Ene",
          align: "end",
          sortable: false,
          value: "january"
        },
        {
          text: "Feb",
          align: "end",
          sortable: false,
          value: "february"
        },
        {
          text: "Mar",
          align: "end",
          sortable: false,
          value: "march"
        },
        {
          text: "Abr",
          align: "end",
          sortable: false,
          value: "april"
        },
        {
          text: "May",
          align: "end",
          sortable: false,
          value: "may",
          width: "5%"
        },
        {
          text: "Jun",
          align: "end",
          sortable: false,
          value: "june"
        },
        {
          text: "Jul",
          align: "end",
          sortable: false,
          value: "july"
        },
        {
          text: "Ago",
          align: "end",
          sortable: false,
          value: "august"
        },
        {
          text: "Sep",
          align: "end",
          sortable: false,
          value: "september"
        },
        {
          text: "Oct",
          align: "end",
          sortable: false,
          value: "october"
        },
        {
          text: "Nov",
          align: "end",
          sortable: false,
          value: "november"
        },
        {
          text: "Dic",
          align: "end",
          sortable: false,
          value: "december"
        },
        {
          text: "Total",
          align: "end",
          sortable: false,
          value: "total"
        }
      ]
    };
  },
  mounted() {
    this.getGoals();
  },
  methods: {
    numeral,
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    getGoals() {
      this.loading = true;
      getGoals(this.date.year())
        .then((response: AxiosResponse<PortfolioGoal[]>) => {
          this.goals = response.data.map((x: PortfolioGoal) => {
            if (x.values.length == 0) {
              for (let j = 0; j < 12; j++) {
                x.values.push(0);
              }
            }
            const valuesBackup: number[] = [];
            for (let i = 0; i < x.values.length; i++) {
              valuesBackup.push(x.values[i]);
            }
            return {
              editing: false,
              saving: false,
              valuesBackup,
              ...x
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startEdition(portfolioGoal: ExtendedPortfolioGoal) {
      portfolioGoal.editing = true;
    },
    setGoals(portfolioGoal: ExtendedPortfolioGoal) {
      portfolioGoal.saving = true;
      setPortfolioGoals(
        portfolioGoal.portfolio.id,
        portfolioGoal.productsGroup.id,
        this.date.year(),
        portfolioGoal.values
      )
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Metas asignadas exitosamente.",
            color: SnackbarColor.success
          });
          for (let i = 0; i < portfolioGoal.values.length; i++) {
            portfolioGoal.valuesBackup[i] = portfolioGoal.values[i];
          }
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Ha habido un problema asignando las metas.",
            color: SnackbarColor.error
          });
          this.cancelEdition(portfolioGoal);
        })
        .finally(() => {
          portfolioGoal.saving = false;
          portfolioGoal.editing = false;
        });
    },
    cancelEdition(portfolioGoal: ExtendedPortfolioGoal) {
      portfolioGoal.editing = false;
      for (let i = 0; i < portfolioGoal.values.length; i++) {
        this.$set(portfolioGoal.values, i, portfolioGoal.valuesBackup[i]);
      }
    }
  },
  computed: {
    Kind: () => Kind,
    RoleName: () => RoleName,
    totals(): number[] {
      const totalsArray: number[] = [];
      for (let i = 0; i < 12; i++) {
        totalsArray.push(
          this.goals
            .map(x => x.values[i])
            .reduce((a: number, b: number) => a + b, 0)
        );
      }
      return totalsArray;
    },
    total(): number {
      return this.totals.reduce((a: number, b: number) => a + b, 0);
    }
  }
});
