import Vue from 'vue'
import Vuex from 'vuex'
import { User, Claim, SnackbarColor, SnackbarData, SystemParameters } from '@/models';
import { MutationTree, ActionTree } from 'vuex'
import { getCurrentUser, getSystemParameters, logout } from '@/api'
import { AxiosError, AxiosResponse } from 'axios'
import * as signalR from "@microsoft/signalr";
import { backendUrl } from "@/api";
import router from '@/router';

export interface RootState {
  authenticated: boolean;
  user: User | null;
  roles: string[];
  claims: Claim[];
  snackbar: SnackbarState;
  scheduleContactForm: ContactFormState;
  informUnscheduledContactForm: ContactFormState;
  parameters: SystemParameters;
  displayDayCalendar: number;
  version: string;
  update: {
    available: boolean;
    registration: ServiceWorkerRegistration | null;
  }
}

interface ContactFormState {
  show: boolean;
  quoteId?: number;
  gapDocumentId?: number;
  nexusId: number;
}

interface SnackbarState {
  show: boolean;
  color: SnackbarColor;
  text: string;
}

// Initial state of the account.
const initialRootState: RootState = {
  authenticated: false,
  user: null,
  roles: [],
  claims: [],
  snackbar: {
    show: false,
    color: SnackbarColor.success,
    text: ''
  },
  scheduleContactForm: {
    show: false,
    nexusId: 0
  },
  informUnscheduledContactForm: {
    show: false,
    nexusId: 0
  },
  parameters: {
    maximumNumberOfDailyVisits: 20,
    maximumNumberOfDaysToPlan: 10,
    maximumNumberOfPlannedVisits: 10,
    closingRate: 10,
    currency: '$',
    daysToCritical: 45,
    maintenanceToActivationDays: 30,
    companyName: 'Sin nombre definido',
    logo: '',
    countryId: 45
  },
  displayDayCalendar: 0,
  version: process.env.VUE_APP_VERSION ?? '',
  update: {
    available: false,
    registration: null
  }
}


// This code makes changes to the state of the store.
// Mutation types
export enum MutationTypes {
  SET_USER = 'SET_USER',
  UNSET_USER = 'UNSET_USER',
  SET_CLAIMS = 'SET_CLAIMS',
  SHOW_SNACK = 'SHOW_SNACK',
  SET_SHOW_SNACK = 'SET_SHOW_SNACK',
  SHOW_SCHEDULE_CONTACT_FORM = 'SHOW_SCHEDULE_CONTACT_FORM',
  HIDE_SCHEDULE_CONTACT_FORM = 'HIDE_SCHEDULE_CONTACT_FORM',
  SHOW_INFORM_UNSCHEDULED_CONTACT_FORM = 'SHOW_INFORM_UNSCHEDULED_CONTACT_FORM',
  HIDE_INFORM_UNSCHEDULED_CONTACT_FORM = 'HIDE_INFORM_UNSCHEDULED_CONTACT_FORM',
  SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE',
  SET_LOGO = 'SET_LOGO',
  SET_SYSTEM_PARAMETERS = 'SET_SYSTEM_PARAMETERS',
  DISPLAY_DAY_CALENDAR = 'DISPLAY_DAY_CALENDAR',
  UPDATE_AVAILABLE = 'UPDATE_AVAILABLE',
  UPDATING = 'UPDATING',
  UPDATED = 'UPDATED'
}

// Actual mutations
const mutations: MutationTree<RootState> = {
  [MutationTypes.SET_USER](state, payload: User) {
    state.authenticated = true;
    state.user =
    {
      ...payload
    };
  },
  [MutationTypes.UNSET_USER](state) {
    state.authenticated = false;
    state.user = null;
    state.roles = [];
    state.claims = [];
  },
  [MutationTypes.SET_CLAIMS](state, payload: Claim[]) {
    state.claims = payload;
  },
  [MutationTypes.SHOW_SNACK](state, payload: SnackbarData) {
    state.snackbar.show = true;
    state.snackbar.color = payload.color;
    state.snackbar.text = payload.text;
  },
  [MutationTypes.SET_SHOW_SNACK](state, payload: boolean) {
    state.snackbar.show = payload;
  },
  [MutationTypes.SHOW_SCHEDULE_CONTACT_FORM](state, payload: { nexusId: number, quoteId?: number, gapDocumentId?: number }) {
    state.scheduleContactForm.nexusId = payload.nexusId;
    if (payload.quoteId && payload.gapDocumentId) {
      console.warn('A quoteId and a gapDocumentId have been provided for a single follow up contact.');
    }
    state.scheduleContactForm.quoteId = payload.quoteId ? payload.quoteId : undefined;
    state.scheduleContactForm.gapDocumentId = payload.gapDocumentId ? payload.gapDocumentId : undefined;
    state.scheduleContactForm.show = true;
  },
  [MutationTypes.HIDE_SCHEDULE_CONTACT_FORM](state) {
    state.scheduleContactForm.show = false;
    state.scheduleContactForm.nexusId = 0;
    state.scheduleContactForm.quoteId = undefined;
    state.scheduleContactForm.gapDocumentId = undefined;
  },
  [MutationTypes.SHOW_INFORM_UNSCHEDULED_CONTACT_FORM](state, payload: { nexusId: number, quoteId?: number, gapDocumentId?: number }) {
    state.informUnscheduledContactForm.nexusId = payload.nexusId;
    if (payload.quoteId && payload.gapDocumentId) {
      console.warn('A quoteId and a gapDocumentId have been provided for a single follow up contact.');
    }
    state.informUnscheduledContactForm.quoteId = payload.quoteId ? payload.quoteId : undefined;
    state.informUnscheduledContactForm.gapDocumentId = payload.gapDocumentId ? payload.gapDocumentId : undefined;
    state.informUnscheduledContactForm.show = true;
  },
  [MutationTypes.HIDE_INFORM_UNSCHEDULED_CONTACT_FORM](state) {
    state.informUnscheduledContactForm.show = false;
    state.informUnscheduledContactForm.nexusId = 0;
    state.informUnscheduledContactForm.quoteId = undefined;
    state.informUnscheduledContactForm.gapDocumentId = undefined;
  },
  [MutationTypes.SET_PROFILE_IMAGE](state, payload) {
    if (state.user) {
      state.user.profileImage = payload;
    }
  },
  [MutationTypes.SET_LOGO](state, payload: string) {
    state.parameters.logo = payload;
  },
  [MutationTypes.SET_SYSTEM_PARAMETERS](state, payload: SystemParameters) {
    state.parameters = payload;
  },
  [MutationTypes.DISPLAY_DAY_CALENDAR](state) {
    state.displayDayCalendar = state.displayDayCalendar + 1;
  },
  [MutationTypes.UPDATE_AVAILABLE](state, payload: ServiceWorkerRegistration) {
    state.update.available = true;
    state.update.registration = payload;
  },
  [MutationTypes.UPDATING](state) {
    state.update.available = false;
  },
  [MutationTypes.UPDATED](state) {
    state.update.available = false;
    state.update.registration = null;
  }
}

export enum ActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  INITIALIZE = 'INITIALIZE',
  REFRESH_APP = 'REFRESH_APP'
}

const actions: ActionTree<RootState, RootState> = {
  [ActionTypes.LOGIN](this) {
    getCurrentUser().then((userResponse: AxiosResponse<User>) => {
      this.commit(MutationTypes.SET_USER, userResponse.data);
      router.push({ name: 'Welcome' });
      console.log("Logged in.");
    });
  },
  [ActionTypes.LOGOUT](this) {
    logout().then(() => {
      this.commit(MutationTypes.UNSET_USER);
      console.log('Logged out.');
    });
    // redirect to login page if we are not there already
    if (router.currentRoute.name !== 'Login') router.push({ name: 'Login' });
  },
  [ActionTypes.INITIALIZE](this) {
    // Check if there is a token registered in the local storage.
    console.log('Initializing session.');

    // This promise allows to ensure that code that depends
    // on user authentication information requests are completed
    // is executed after these requests are finished.
    return Promise.all([
      getCurrentUser()
        .then((response: AxiosResponse<User>) => {
          this.commit(MutationTypes.SET_USER, response.data);
          // Logging out if the user is not active.
          if (!response.data.isActive) {
            this.dispatch(ActionTypes.LOGOUT);
          }
          // Subscribing to signalR account hub.
          const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${backendUrl}/accountHub`)
            .build();
          connection.on("logoutSignal", () => {
            console.log(`Logging out because your user account has been deactivated.`);
            this.dispatch(ActionTypes.LOGOUT);
          });
          connection.start().then(() => {
            connection.invoke('JoinGroup', `User_${response.data.id}`).catch(error => console.log(error));
            console.log(`Registering on group User_${response.data.id}.`);
          }).catch(error => console.error(error));
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        }),
      getSystemParameters()
        .then((response: AxiosResponse<SystemParameters>) => {
          this.commit(MutationTypes.SET_SYSTEM_PARAMETERS, response.data);
        })
    ]).catch(() => {
      // Push to login
      router.push({ name: 'Login' });
    });
  },
  [ActionTypes.REFRESH_APP](this) {
    this.commit(MutationTypes.UPDATING);
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.state.update.registration || !this.state.update.registration.waiting) return
    // send message to SW to skip the waiting and activate the new SW
    this.state.update.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload();
    this.commit(MutationTypes.UPDATED);
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: initialRootState,
  mutations,
  actions
})