




















































































































































































import { getNexus, getObjectives, getPortfolios, getQuote } from "@/api";
import {
  BaseScheduledContact,
  ContactKind,
  ContactType,
  Nexus,
  Objective,
  Portfolio,
  Quote,
  ValidationErrors
} from "@/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import Vue from "vue";
import numeral from "numeral";
import TimeInput from "@/components/TimeInput.vue";

interface FormData {
  dateMenu: boolean;
  date: string;
  timeMenu: boolean;
  time: string;
  nexus: Nexus;
  objectives: Objective[];
  portfolios: Portfolio[];
  quote?: Quote;
  selectedPortfolio: Portfolio | null;
}

const defaultNexus: Nexus = {
  id: 0,
  name: "",
  lastName: "",
  email: "",
  phone: "",
  mobilePhone: "",
  position: "",
  officeNumber: "",
  isMain: false,
  isConfirmed: false,
  isActive: false
};

const defaultScheduledContact: BaseScheduledContact = {
  nexusId: 0,
  dateTime: dayjs(),
  contactTypeId: 0,
  objectiveId: 0,
  portfolioId: 0,
  observations: ""
};

export default Vue.extend({
  components: {
    TimeInput
  },
  props: {
    scheduledContact: Object as () => BaseScheduledContact,
    nexusId: Number,
    kind: String as () => ContactKind,
    errors: Object as () => ValidationErrors,
    quoteId: Number,
    editing: {
      type: Boolean,
      default: false
    }
  },
  data(): FormData {
    return {
      dateMenu: false,
      timeMenu: false,
      date: this.scheduledContact.dateTime.format("YYYY-MM-DD"),
      time: this.scheduledContact.dateTime.format("HH:mm:ss"),
      nexus: { ...defaultNexus },
      objectives: [],
      portfolios: [],
      selectedPortfolio: null,
      quote: undefined
    };
  },
  mounted() {
    this.initializeTimes();
    this.initialize();
  },
  methods: {
    numeral,
    initializeTimes() {
      if (this.kind == ContactKind.Scheduled && !this.editing) {
        const dateTime = dayjs().add(1, "hours");
        this.date = dateTime.format("YYYY-MM-DD");
        this.time = dateTime.format("HH:mm:ss");
      } else if (!this.editing) {
        const dateTime = dayjs();
        this.date = dateTime.format("YYYY-MM-DD");
        this.time = dateTime.format("HH:mm:ss");
      }
    },
    initialize() {
      getNexus(this.nexusId).then((response: AxiosResponse<Nexus>) => {
        this.nexus = response.data;
        const salespersonId = this.$store.state.user.isSalesperson
          ? this.$store.state.user.id
          : null;
        getPortfolios(
          salespersonId,
          null,
          this.nexus.facility?.client?.id
        ).then((pResponse: AxiosResponse<Portfolio[]>) => {
          this.portfolios = pResponse.data;
          if (this.portfolios.length > 0 && !this.editing)
            this.scheduledContact.portfolioId = this.portfolios[0].id;
          this.selectedPortfolio = { ...this.portfolios[0] };
        });
      });
      getObjectives().then((response: AxiosResponse<Objective[]>) => {
        this.objectives = response.data;
        if (!this.editing)
          this.scheduledContact.objectiveId = this.objectives[0].id;
      });
      this.getQuote();
    },
    getQuote() {
      if (this.quoteId) {
        getQuote(this.quoteId).then((response: AxiosResponse<Quote>) => {
          this.quote = response.data;
        });
      } else this.quote = undefined;
    },
    allowedDates(val: string) {
      if (this.kind == ContactKind.Scheduled) {
        return val >= defaultScheduledContact.dateTime.format("YYYY-MM-DD");
      } else {
        return val == defaultScheduledContact.dateTime.format("YYYY-MM-DD");
      }
    },
    allowedHours(val: string) {
      const currentDateTimeString = dayjs().format("YYYY-MM-DD");
      if (this.kind == ContactKind.Scheduled) {
        if (this.date > currentDateTimeString) {
          return true;
        } else {
          return val > dayjs().format("HH");
        }
      } else {
        return (
          this.date == currentDateTimeString && val <= dayjs().format("HH")
        );
      }
    }
  },
  computed: {
    dateFormatted(): string {
      return this.scheduledContact.dateTime.format("DD-MM-YYYY");
    },
    computedContactTypes(): ContactType[] {
      return this.selectedPortfolio && this.selectedPortfolio.salespersonType
        ? this.selectedPortfolio.salespersonType.availableContactTypes.filter(
            x => x.kind == this.kind
          )
        : [];
    }
  },
  watch: {
    date: function(): void {
      this.scheduledContact.dateTime = dayjs(`${this.date}T${this.time}:00`);
    },
    time: function(): void {
      this.scheduledContact.dateTime = dayjs(`${this.date}T${this.time}:00`);
    },
    kind: function(): void {
      if (this.editing) return;
      this.scheduledContact.contactTypeId = this.computedContactTypes[0].id;
      if (this.kind === ContactKind.Scheduled) {
        const inOneHour = dayjs().add(1, "hours");
        this.date = inOneHour.format("YYYY-MM-DD");
        this.time = inOneHour.format("HH:mm:ss");
      } else {
        this.date = dayjs().format("YYYY-MM-DD");
        this.time = dayjs().format("HH:mm:ss");
      }
    },
    nexusId: function(): void {
      if (this.nexusId > 0) {
        this.initialize();
        this.initializeTimes();
      }
    },
    quoteId: function(): void {
      this.getQuote();
    },
    selectedPortfolio: function(): void {
      if (this.computedContactTypes.length > 0) {
        this.scheduledContact.contactTypeId = this.computedContactTypes[0].id;
      }
    }
  }
});
