














































































































































































































































































































































































































































































































import {
  addSale,
  confirmGapDocument,
  deleteGapDocument,
  editGapDocument,
  getPortfolios,
  getGapDocuments,
  getGapDocumentSales,
  rejectGapDocument
} from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import ContextMenuOption from "@/components/ContextMenuOption.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import SaleForm from "@/views/sales/SaleForm.vue";
import Modal from "@/components/Modal.vue";
import {
  Nexus,
  PaginatedResult,
  GapDocument,
  GapDocumentData,
  GapDocumentsQuery,
  SnackbarColor,
  ValidationErrors,
  RoleName,
  SaleData,
  Sale,
  Portfolio
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import GapDocumentForm from "@/views/gap_documents/GapDocumentForm.vue";
import dayjs from "dayjs";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";
import numeral from "numeral";
import { authorizeRoles } from "@/helpers";

export interface GapDocumentsTableData {
  // Table data, filters, pagination and errors.
  gapDocuments: GapDocument[];
  totalGapDocuments: number;
  loading: boolean;
  options: DataOptions;
  headers: DataTableHeader[];
  search?: string;
  inGap?: boolean;
  error: string | null;
  portfoliosForFilter: Portfolio[];
  portfolioId: number;

  // GapDocument edition.
  showEditForm: boolean;
  editedGapDocumentId: number;
  editing: boolean;

  // GapDocument deletion.
  showDeleteModal: boolean;
  deletedGapDocumentId: number;
  deleting: boolean;

  // Nexus selection (required to display contact form).
  showNexusModal: boolean;
  nexuses: Nexus[];
  followUpNexusId: number;
  followUpGapDocumentId: number;

  // Sale creation.
  showSaleCreateForm: boolean;
  creatingSale: boolean;
  saleGapDocumentId: number;
  saleData: SaleData;

  // Purchase order form.
  gapDocumentData: GapDocumentData;

  // Validation errors (for all forms and requests).
  validationErrors: ValidationErrors;

  // Purchase order confirmation.
  confirmedGapDocumentId: number;
  showConfirmationModal: boolean;

  // Purchase order rejection.
  rejectedGapDocumentId: number;
  showRejectionModal: boolean;

  // Confirmation or rejection.
  processing: boolean;
  gapDocumentCode: string;

  // Display quote sales.
  showGapDocumentSales: boolean;
  gapDocumentSales: Sale[];

  // Totals
  computedValues: { [key: string]: number };
  counts: { [key: string]: number };
}

const initialOptions: DataOptions = {
  sortBy: ["value"],
  sortDesc: [true],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

const defaultGapDocumentData: GapDocumentData = {
  code: "",
  value: 0,
  date: dayjs()
};

const defaultSaleData: SaleData = {
  code: "",
  value: 0,
  date: dayjs()
};

export default Vue.extend({
  components: {
    ButtonToqui,
    ButtonCircle,
    Modal,
    GapDocumentForm,
    RequireConfirmation,
    SaleForm,
    ContextMenu,
    ContextMenuOption
  },
  data(): GapDocumentsTableData {
    return {
      totalGapDocuments: 0,
      gapDocuments: [],
      loading: true,
      search: "",
      inGap: true,
      options: initialOptions,
      portfoliosForFilter: [],
      portfolioId: 0,
      error: null,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "code",
          width: "18%"
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "client",
          width: "28%"
        },
        {
          text: `Valor [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: true,
          value: "value",
          width: "23%"
        },
        {
          text: "Fecha de Emisión",
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      showEditForm: false,
      editedGapDocumentId: 0,
      editing: false,
      showDeleteModal: false,
      deletedGapDocumentId: 0,
      deleting: false,
      gapDocumentData: { ...defaultGapDocumentData },
      validationErrors: {},
      showNexusModal: false,
      nexuses: [],
      followUpNexusId: 0,
      followUpGapDocumentId: 0,
      showSaleCreateForm: false,
      creatingSale: false,
      saleGapDocumentId: 0,
      confirmedGapDocumentId: 0,
      processing: false,
      showConfirmationModal: false,
      rejectedGapDocumentId: 0,
      showRejectionModal: false,
      gapDocumentCode: "",
      saleData: { ...defaultSaleData },
      showGapDocumentSales: false,
      gapDocumentSales: [],
      computedValues: {},
      counts: {
        InGap: 0,
        All: 0
      }
    };
  },
  mounted() {
    this.getDataFromApi();
    getPortfolios().then((response: AxiosResponse<Portfolio[]>) => {
      // This map is to create a separate array than the used to filter this view by portfolio.
      this.portfoliosForFilter = response.data;
      this.portfoliosForFilter.unshift({
        id: 0,
        name: "Todas las carteras",
        clientsCount: 0
      });
    });
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    numeral,
    filterInGap() {
      this.inGap = true;
      this.getDataFromApi(true);
    },
    filterAll() {
      this.inGap = false;
      this.getDataFromApi(true);
    },
    getDataFromApi(resetPage = false) {
      this.loading = true;
      this.error = null;
      if (resetPage) this.options.page = 1;
      const params: GapDocumentsQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage,
        text: this.search,
        portfolioId: this.portfolioId === 0 ? undefined : this.portfolioId,
        inGap: this.inGap,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      };
      getGapDocuments(params)
        .then((response: AxiosResponse<PaginatedResult<GapDocument>>) => {
          this.gapDocuments = response.data.data;
          this.totalGapDocuments = response.data.totalCount;
          this.computedValues = response.data.computedValues;
          this.counts = response.data.counts;
          this.loading = false;
        })
        .catch(() => {
          this.gapDocuments = [];
          this.totalGapDocuments = 0;
          this.loading = false;
          this.error = "Ha habido un error consultando los datos solicitados.";
        });
    },
    startEdition(gapDocument: GapDocument) {
      this.showEditForm = true;
      this.gapDocumentData = {
        code: gapDocument.code,
        value: gapDocument.value,
        date: gapDocument.date
      };
      this.editedGapDocumentId = gapDocument.id;
    },
    editGapDocument() {
      this.editing = true;
      editGapDocument(this.editedGapDocumentId, this.gapDocumentData)
        .then(() => {
          this.closeEditForm();
          this.getDataFromApi();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.editing = false;
        });
    },
    closeEditForm() {
      this.showEditForm = false;
      this.editing = false;
      this.gapDocumentData = {
        ...defaultGapDocumentData
      };
      this.editedGapDocumentId = 0;
      this.validationErrors = {};
    },
    startDeletion(gapDocument: GapDocument) {
      this.showDeleteModal = true;
      this.deletedGapDocumentId = gapDocument.id;
    },
    deleteGapDocument() {
      deleteGapDocument(this.deletedGapDocumentId)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Documento de brecha eliminado exitosamente.`,
            color: SnackbarColor.success
          });
          this.getDataFromApi();
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `No fue posible eliminar el documento de brecha.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.deletedGapDocumentId = 0;
        });
    },
    startSaleCreation(gapDocument: GapDocument) {
      this.showSaleCreateForm = true;
      this.saleGapDocumentId = gapDocument.id;
    },
    addSale() {
      this.creatingSale = true;
      addSale(this.saleGapDocumentId, this.saleData)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Venta creada exitosamente.`,
            color: SnackbarColor.success
          });
          this.getDataFromApi();
          this.closeSaleCreationForm();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: `Ha habido un problema creando la factura.`,
              color: SnackbarColor.error
            });
          }
        })
        .finally(() => {
          this.creatingSale = false;
        });
    },
    closeSaleCreationForm() {
      this.saleGapDocumentId = 0;
      this.creatingSale = false;
      this.showSaleCreateForm = false;
      this.saleData = { ...defaultSaleData };
    },
    startConfirmation(gapDocument: GapDocument) {
      this.showConfirmationModal = true;
      this.confirmedGapDocumentId = gapDocument.id;
      this.gapDocumentCode = gapDocument.code;
    },
    confirm() {
      this.processing = true;
      confirmGapDocument(this.confirmedGapDocumentId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Documento de brecha confirmado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema confirmando el documento de brecha.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelConfirmation();
        });
    },
    cancelConfirmation() {
      this.showConfirmationModal = false;
      this.confirmedGapDocumentId = 0;
    },
    startRejection(gapDocument: GapDocument) {
      this.showRejectionModal = true;
      this.rejectedGapDocumentId = gapDocument.id;
      this.gapDocumentCode = gapDocument.code;
    },
    reject() {
      this.processing = true;
      rejectGapDocument(this.rejectedGapDocumentId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Documento de brecha rechazado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema rechazando el documento de brecha.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelRejection();
        });
    },
    cancelRejection() {
      this.showRejectionModal = false;
      this.rejectedGapDocumentId = 0;
    },
    displayGapDocumentSales(gapDocument: GapDocument) {
      this.showGapDocumentSales = true;
      getGapDocumentSales(gapDocument.id).then(
        (response: AxiosResponse<Sale[]>) => {
          this.gapDocumentSales = response.data;
        }
      );
      // TODO: handle failures
    }
  },
  computed: {
    ToquiKind: () => ToquiKind,
    Kind: () => Kind,
    RoleName: () => RoleName
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  }
});
